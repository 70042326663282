import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Avatar, useTheme, Button } from '@mui/material';
import { format } from 'date-fns';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { GroupGetType } from '../../../../types/api/groups/GroupGetType';
import { dateFormat } from '../../../../../../core/helpers/dateFormat';
import NoDataComponent from '../../../../components/shared/travelTable/components/NoDataComponent';
import { title } from 'process';
import TableTitle from '../../../../components/tables/tableTitle/TableTitle';
import { useDropdownContext } from '../../../../../../core/context/DropdownContext';
import GroupTableRow from './GroupTableRow';

interface GroupTableProps {
    groups: GroupGetType[]
}

const GroupTable: FC<GroupTableProps> = ({ groups }) => {
    const { setOpenDropdown } = useDropdownContext();

    return (
        <TableContainer component={Paper} sx={{ boxShadow: 5, borderRadius: '6px' }}>
          <TableTitle title="All groups">
                <Button variant='outlined' size='small' onClick={() => setOpenDropdown('addGroup')}>+ Add group</Button>
            </TableTitle>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
              {groups.map((group) => (
                <GroupTableRow group={group}/>
              ))}
            </TableBody>
            </Table>
            {groups.length === 0 && <NoDataComponent size={1} title='No groups' details='You do not have any groups yet'/>}
        </TableContainer>
    );
}

export default GroupTable;
