import type { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isAuthenticated } from './isAuth';

interface ProtectedRouteProps {}

const ProtectedRoute: FC<ProtectedRouteProps> = () => {
    
    if (!isAuthenticated()) {
        return <Navigate to="/auth/login" replace />;
    }
    return <Outlet/>;
}

export default ProtectedRoute;
