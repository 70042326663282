import { FormControl, InputLabel, MenuItem, Select, SxProps, Theme, useTheme } from '@mui/material';

interface CustomSelectProps<T> {
    value: T;
    onChange: (id: number) => void; 
    options: { id: number, name: string }[];
    label: string,
    standard? : boolean
    sx?: SxProps<Theme>; 
}

const CustomSelect = <T,>({ value, onChange, options, label, standard, sx }: CustomSelectProps<T>) => {
    const theme = useTheme();
    
    return (
        <FormControl sx={{ width: '100%'}}>
            <InputLabel sx={{color : theme.palette.primary.main}} id="custom-select-label">{label}</InputLabel>
            <Select 
                variant={standard ? 'standard' : undefined}
                labelId="custom-select-label"
                id="custom-select"
                value={value}
                label={label}
                sx={{ 
                    color: theme.palette.text.primary, 
                    fontWeight: '400',
                    fontSize: '14px', 
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.primary.main, 
                    },
                    '& .MuiSelect-icon': {
                        color: theme.palette.text.primary, // Kolor ikony
                    },
                    ...sx,
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option.id} value={option.id} onClick={() => onChange(option.id)}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default CustomSelect;

