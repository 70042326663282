import { TableContainer, Paper, Table, TableRow, TableCell, Box, TableBody, Avatar, Button, useTheme, Typography } from '@mui/material';
import type { FC } from 'react';
import NoDataComponent from '../../../components/shared/travelTable/components/NoDataComponent';
import { GroupTravelType } from '../../../types/api/travels/groupTravelType';
import { deleteTravelFromGroup } from '../../../../../services/groupService';
import { useDropdownContext } from '../../../../../core/context/DropdownContext';
import AddTravelToGroup from './AddTravelToGroup';
import ActionDropdown from '../../../components/actionDropdown/ActionDropdown';
import { canAddTravel, canRemoveTravels } from '../permisions';
import Loader from '../../../../../shared/ui/loader/Loader';
import { useAppContext } from '../../../../../core/context/AppContext';
import TableTitle from '../../../components/tables/tableTitle/TableTitle';
import GroupTravelsRow from './GruopTravelsRow';

interface GroupTravelListProps {
    groupId: number;
    travels: GroupTravelType[];
    onTravelDelete: (travelId: number) => void;
    onTravelAdd: () => void;
    isOwner: boolean,
    role: string
}

const GroupTravelList: FC<GroupTravelListProps> = ({ travels, groupId, onTravelDelete, onTravelAdd, isOwner, role }) => {
    const { openDropdown, setOpenDropdown } = useDropdownContext();
    const { isLoading } = useAppContext();

    const travelDelete = async (travelId: number) => {
        await deleteTravelFromGroup(travelId, groupId)
            .then(res => {
                onTravelDelete(travelId);
            })
    }

    const travelAdd = () => {
        setOpenDropdown(null);
        onTravelAdd();
    }

    return (
        <TableContainer component={Paper} sx={{ boxShadow: 5, borderRadius: '6px', height: '100%', position: 'relative', overflow: 'visible' }}>
            <TableTitle title="Group's travels">
                {canAddTravel(isOwner, role) &&
                    <Button variant='outlined' size='small' onClick={() => setOpenDropdown('addTravelToGroup')}>+ Add travel</Button>
                }
            </TableTitle>
            <Table aria-label="simple table">
                <TableBody sx={{ position: 'relative' }}>
                    {isLoading ? <Loader /> : null}
                    {travels.map((travel) => (
                        <GroupTravelsRow
                            travel={travel}
                            isOwner={isOwner}
                            role={role}
                            onDeleteClick={(travelId) => travelDelete(travelId)}
                        />
                    ))}
                </TableBody>
            </Table>
            {
                travels.length < 1 ?
                    <NoDataComponent
                        size={1}
                        title='No data'
                        details="You don not have any trips yet"
                    /> : null
            }
            {openDropdown === 'addTravelToGroup' &&
                <ActionDropdown sx={{ maxWidth: '600px', width: '100%', right: '25px' }}>
                    <AddTravelToGroup
                        onTravelsAdd={travelAdd}
                    />
                </ActionDropdown>
            }
        </TableContainer>
    );
}

export default GroupTravelList;
