import { Box, Typography, useTheme } from '@mui/material';
import { type FC } from 'react';
import { useNavContext } from '../../context/NavContext';
import { navItems } from './navItems/navItems';
import { NavItemsType } from '../../types/ui/navItemsType';
import NavItem from './navItem/NavItem';
import Logo from '../../../../core/ui/logo/Logo';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

interface NavProps { }

const Nav: FC<NavProps> = () => {
    const { isMenuOpen, setMenuState } = useNavContext();
    const nav: NavItemsType[] = navItems;
    const theme = useTheme();

    return (
        <Box sx={{
            position: 'fixed',
            left: '0',
            top: '0',
            minHeight: '100vh',
            zIndex: 1,
            width: '240px',
            boxShadow: 3,
            transition: 'all ease-in-out 0.2s',
            transform: isMenuOpen ? 'translateX(0)' : 'translateX(-100%)',
            backgroundColor: 'white'
        }}>
            <Box sx={{
                padding: '30px 30px',
                width: '75%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Logo
                    size={3}
                />
                <MenuOpenIcon 
                    sx={{cursor: 'pointer', color: theme.palette.text.secondary}}
                    onClick={() => setMenuState(false)}
                />
            </Box>
            {nav.map((navSection, index) => (
                <>
                    <Typography
                        variant='body2'
                        color={theme.palette.text.secondary}
                        key={index}
                        sx={{
                            margin: '20px 0 20px 28px',
                            textTransform: 'uppercase',
                            fontWeight: '500',
                            fontSize: '10px',
                            letterSpacing: '0.5px'
                        }}>
                        {navSection.title}
                    </Typography>
                    {navSection.items.map((navItem, index) => (
                        <NavItem navItem={navItem} key={index} />
                    ))}
                </>
            ))}
        </Box>
    );
}

export default Nav;
