import { Box, Typography, useTheme } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import type { FC } from 'react';
import { useAccountConfirmationContext } from '../../../../core/context/AccountConfirmationContext';
import { resendConfirmationToken } from '../../../../services/authService';
import { useAppContext } from '../../../../core/context/AppContext';

interface NoActiveAccountProps {}

const NoActiveAccount: FC<NoActiveAccountProps> = () => {
    const theme = useTheme();
    const { setAccountConfirmation } = useAccountConfirmationContext();
    const { setSuccess } = useAppContext();

    const resendToken = async() => {
        await resendConfirmationToken()
            .then(res => {
                setAccountConfirmation(1);
                setSuccess({status : 'Account confirmation token sended. Check email'});
            })
    }

    return (
        <Box sx={{width: '100%', position: 'fixed', left: 0, top: 0, zIndex: 5, backgroundColor: theme.palette.primary.main, color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography sx={{padding:'5px 20px'}}>
                Your account is not confirmed and will expire in <b>7 days</b>. Go to your email address and confirm your account. If you haven't received the link, <Box sx={{cursor: 'pointer', display: 'inline', fontWeight: '700'}} onClick={() => resendToken()}>click here</Box>. 
            </Typography>
            <ClearIcon sx={{cursor: 'pointer', padding: '0 10px'}} onClick={() => setAccountConfirmation(1)}/>
        </Box>
    );
}

export default NoActiveAccount;
