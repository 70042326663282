import { useState, type FC } from 'react';
import DynamicForm from '../../../../../shared/ui/form/DynamicForm';
import { addGroupFormConfig } from '../../../forms/addGroupFormConfig';
import { Box,Typography } from '@mui/material';
import { GroupPostType } from '../../../types/api/groups/GroupPostType';
import { GroupGetType } from '../../../types/api/groups/GroupGetType';
import { createGroup } from '../../../../../services/groupService';
import { LoadingButton } from '@mui/lab';
import { useAppContext } from '../../../../../core/context/AppContext';

interface AddGroupProps {
    onSuccess: (group: GroupGetType) => void
}

const AddGroup: FC<AddGroupProps> = (props) => {
    const { isLoading } = useAppContext();
    const [formValues, setFormValues] = useState<GroupPostType>({ name: '', description: '' });

    const onChange = (data: Record<string, string | number>) => {
        setFormValues(prev => ({
            ...prev,
            name: String(data.name || ''),
            description: String(data.description || '')
        }));
    };

    const onSubmit = async () => {
        await createGroup(formValues)
            .then(res => {
                props.onSuccess(res.data);
            })
    }

    return (
        <Box sx={{ padding: '20px' }}>
            <Typography>Create group</Typography>
            <DynamicForm
                formConfig={addGroupFormConfig}
                onChange={(data) => onChange(data)}
            />
            <LoadingButton
                loading={isLoading}
                fullWidth
                variant='contained'
                sx={{ marginTop: '20px' }}
                onClick={() => onSubmit()}>Submit
            </LoadingButton>
        </Box>
    );
}

export default AddGroup;
