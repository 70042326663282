import type { FC } from 'react';
import logoImage from '../../../assets/images/logo.png';

interface LogoProps {
    size : number
}

const Logo: FC<LogoProps> = (props) => {
    const logoSize = props.size * 10;
    
    return (
        <img src={logoImage} height={logoSize}></img>
    );
}

export default Logo;
