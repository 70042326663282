import { Grid, Typography } from '@mui/material';
import { useEffect, useState, type FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { editTravelDetails, getTravelDetails, getTravelsPaths, getTravelTypes } from '../../../../services/travelsService';
import TravelStats from './components/TravelStats';
import { TravelStatsItemType } from '../../types/ui/travelStatsItem';
import { TravelDetailsType } from '../../types/api/travels/travelDetails';
import { PathType } from '../../types/api/paths/PathType';
import SingleTravelMap from '../../components/shared/map/SingleTravelMap';
import TravelDetailsForm, { FormValues } from './components/TravelDetailsForm';
import ContentWrapper from '../../components/contentWrapper/contentWrapper';
import { TravelEditType } from '../../types/api/travels/travelEditType';
import { AxiosError } from 'axios';

interface TravelDetailsProps { }

const TravelDetails: FC<TravelDetailsProps> = () => {
    const { travelId } = useParams();
    const navigate = useNavigate();

    const [travelDetails, setTravelDetails] = useState<TravelDetailsType | undefined>(undefined);
    const [travelStats, setTravelStats] = useState<TravelStatsItemType | undefined>(undefined);
    const [travelPath, setTravelPath] = useState<PathType[]>([]);
    const [travelsTypes, setTravelTypes] = useState<{id : number, name : string}[]>([]);

    const fetchData = async () => {
        if (!travelId) return;
        await getTravelDetails(parseInt(travelId))
            .then(res => {
                setTravelDetails(res.data);
                convertStats(res.data);
            })
            .catch((e : AxiosError) => {
                if(e.status === 404) navigate('/not-found')
            })
        fetchTravelTypes();
        fetchPaths(parseInt(travelId));
    }

    const fetchPaths = async (travelId: number) => {
        await getTravelsPaths(travelId)
            .then(res => {
                setTravelPath(res.data);
            })
    }

    const fetchTravelTypes = async() => {
        await getTravelTypes()
            .then(res => {
                setTravelTypes(res.data);
            })
    }

    const convertStats = (travelDetails: TravelDetailsType) => {
        const stats: TravelStatsItemType = {
            distance: travelDetails.distance,
            duration: travelDetails?.duration,
            avgSpeed: travelDetails.avgSpeed,
            maxSpeed: travelDetails.maxSpeed
        }
        setTravelStats(stats);
    }

    const editTravel = async (formValue : FormValues) => {
        const editedData : TravelEditType = {
            name : formValue.travelName,
            description : formValue.description,
            travelType : formValue.travelType,
            visibility : 'public'
        }
        await editTravelDetails(Number(travelId), editedData)
            .then(res => {
               
            });
    } 

    useEffect(() => {
        document.title = "FollowMe | Travel Details"; 
        fetchData();
    }, [])

    return (
        <ContentWrapper pageTitle='Travel details'>
            <Grid item xs={12}>
                {travelDetails &&
                    <TravelDetailsForm
                        travelTypes={travelsTypes}
                        travelDetails={{
                            travelName: travelDetails?.name,
                            travelType: travelDetails?.travelType ? travelDetails.travelType.id : 1,
                            date: travelDetails?.travelDate,
                            description: travelDetails?.description
                        }}
                        onEdit={(form) => editTravel(form)}
                        travelId={Number(travelId)}
                    />
                }
            </Grid>
            <Grid item xs={12}>
                <TravelStats
                    travelStats={travelStats}
                />
            </Grid>
            <Grid item xs={12}>
                <SingleTravelMap
                    paths={travelPath}
                />
            </Grid>
        </ContentWrapper>
    );
}

export default TravelDetails;
