import { FC, useEffect } from "react";
import { useMap } from "react-leaflet";
import 'leaflet/dist/leaflet.css';

interface MapControlsProps {
    tripCoordinates: Array<Array<[number, number]>>; 
}

const MapControls: FC<MapControlsProps> = ({ tripCoordinates }) => {
    const map = useMap();

    useEffect(() => {
        if (tripCoordinates.length > 0) {
            const bounds = tripCoordinates.flatMap(path => path);
            map.fitBounds(bounds as [number, number][]);
        }
    }, [tripCoordinates, map]);

    return null;
}

export default MapControls;