import { useEffect, type FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface NotFoundRedirectProps {}

const NotFoundRedirect: FC<NotFoundRedirectProps> = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/not-found');
    }, [])

    return (<></>);
}

export default NotFoundRedirect;
