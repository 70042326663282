import { useEffect, useState, type FC } from 'react';
import ContentWrapper from '../../components/contentWrapper/contentWrapper';
import TravelsTable from '../../components/shared/travelTable/TravelsTable';
import { Grid, Typography } from '@mui/material';
import { PaginatedTravelsType } from '../../types/api/travels/paginatedTravels';
import { getTravels } from '../../../../services/travelsService';
import TravelFilters from './components/travelFilters/TravelFilters';
import { TravelFiltersType } from './types/travelFiltersType';
import { convertFiltersToUrl } from './helpers/convertFiltersToUrl';
import { TravelSortingType } from '../../types/ui/travelSortingType';

interface TravvelsPageProps { }

const TravvelsPage: FC<TravvelsPageProps> = () => {
    const [data, setData] = useState<PaginatedTravelsType | undefined>(undefined);
    const [appliedFilters, setFilters] = useState<string | undefined>(undefined);
    const [sortingOptions, setSortingOptions] = useState<TravelSortingType>({sortField: undefined, sortOrder: undefined});

    const fetchData = async (page: number, filtersUrlParams : string | undefined, sortingOptions : TravelSortingType) => {
        resetState();
        await getTravels(page, filtersUrlParams, sortingOptions)
            .then(res => {
                setData(res.data);
            })
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setData((prev) => ({
            ...prev!,
            page: value,
        }));
    };

    const handleSorting = (sortingOptions : TravelSortingType) =>{
        setSortingOptions(sortingOptions);
        fetchData(1, appliedFilters, sortingOptions);
    }

    useEffect(() => {
        document.title = "FollowMe | Travels"; 
        fetchData((data?.page || 1), appliedFilters, sortingOptions)
    }, [data?.page])

    const handleFilterApplied = async(filters : TravelFiltersType) => {
        const filtersPath = convertFiltersToUrl(filters, sortingOptions)
        setFilters(filtersPath);
        await fetchData(1, filtersPath, sortingOptions);
    }

    const handleClearFilters = async() => {
        setSortingOptions({sortField: undefined, sortOrder: undefined});
        setFilters(undefined);
        await fetchData(1, undefined, sortingOptions);
    }

    const resetState = () => {
        if(data)
        setData({
            ...data,
            paginatedData : []
        });
    }

    return (
        <ContentWrapper
            pageTitle='Your travels'
        >
            <Grid item xs={12}>
                <TravelFilters 
                    onFilterApplay={handleFilterApplied}
                    onClearFilters={handleClearFilters}
                />
            </Grid>
            <Grid item xs={12}>
                <TravelsTable
                    travels={data?.paginatedData || []}
                    pagination={{
                        totalPages: data?.totalPages || 1,
                        currentPage: data?.page || 1,
                        onChangePage: (event, page) => handlePageChange(event, page),
                    }}
                    sorting={{
                        currentOptions: sortingOptions,
                        onSortingApplay: (sortingOptions) => handleSorting(sortingOptions)
                    }}
                />
            </Grid>
        </ContentWrapper>
    );
}

export default TravvelsPage;
