import { Box, useTheme } from '@mui/material';
import type { FC, ReactNode } from 'react';

interface TableTitleProps {
    title : string
    children?: ReactNode; 
}

const TableTitle: FC<TableTitleProps> = (props) => {
    const theme = useTheme();

    return (
        <Box sx={{
            padding: '20px 25px',
            color: theme.palette.text.primary,
            fontWeight: 400,
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${theme.palette.text.disabled}`
        }}>
            <Box>{props.title}</Box>
            {props.children}
        </Box>
    )
}

export default TableTitle;
