import { Box, FormControl, List, TextField, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import NoDataComponent from '../shared/travelTable/components/NoDataComponent';

interface ListDropdownProps<T> {
    titlePlaceholder: string;
    fetchItems: (search: string) => Promise<T[]>; // Funkcja pobierania danych
    onSubmit: (selectedIds: number[]) => Promise<void>; // Funkcja wysyłania danych
    renderItem: (item: T, isSelected: boolean, handleToggle: (id: number) => void) => React.ReactNode; // Funkcja renderująca element listy
    noDataMessage: string; // Wiadomość, gdy brak danych
}

const ListDropdown = <T extends { id: number }>({ 
    titlePlaceholder, 
    fetchItems, 
    onSubmit, 
    renderItem, 
    noDataMessage 
}: ListDropdownProps<T>) => {
    const theme = useTheme();
    const [searchParams, setSearchParams] = useState('');
    const [items, setItems] = useState<T[]>([]);
    const [checked, setChecked] = useState<number[]>([]);

    const handleToggle = (id: number) => {
        setChecked((prev) => prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]);
    };

    const fetchAndSetItems = async () => {
        const fetchedItems = await fetchItems(searchParams);
        setItems(fetchedItems);
    };

    const handleSubmit = async () => {
        await onSubmit(checked);
        setChecked([]);
    };

    useEffect(() => {
        fetchAndSetItems();
    }, [searchParams]);

    return (
        <Box sx={{ boxShadow: 5, width: '100%' }}>
            <Box
                sx={{
                    width: '100%',
                    borderBottom: `1px solid ${theme.palette.text.disabled}`,
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <FormControl
                    variant="outlined"
                    sx={{ margin: '0 0 0 20px', maxWidth: '250px', display: 'flex', flexDirection: 'row' }}
                >
                    <TextField
                        placeholder={titlePlaceholder}
                        value={searchParams}
                        onChange={(e) => setSearchParams(e.target.value)}
                        sx={{
                            background: theme.palette.background.default,
                            '& .MuiInputBase-input': { fontSize: '13px' },
                        }}
                        size="small"
                    />
                </FormControl>
                <LoadingButton
                    variant="contained"
                    sx={{ margin: '0 20px 0 0' }}
                    size="small"
                    onClick={handleSubmit}
                >
                    Submit
                </LoadingButton>
            </Box>
            {items.length > 0 ? (
                <List dense sx={{ width: '100%', bgcolor: 'background.paper', maxHeight: '200px', overflowY: 'auto'  }}>
                    {items.map((item) => renderItem(item, checked.includes(item.id), handleToggle))}
                </List>
            ) : (
                <NoDataComponent size={2} title="" details={noDataMessage} />
            )}
        </Box>
    );
};

export default ListDropdown;
