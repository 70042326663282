import { AxiosResponse } from "axios";
import api from "../api/config";
import { GroupGetType } from "../pages/user/types/api/groups/GroupGetType";
import { GroupPostType } from "../pages/user/types/api/groups/GroupPostType";
import { GroupDetailsType } from "../pages/user/types/api/groups/groupDetailsType";
import { GroupMemberType } from "../pages/user/types/api/groups/GroupMemberType";
import { ApiSuccessResponse } from "../core/types/successResponseType";
import { PermissionType } from "../pages/auth/types/permissionType";

const endpoint = 'travel-groups'

export const getGroupsToSeletc = async() : Promise<AxiosResponse<{id : number, name : string}[]>> => {
    return await api.get(endpoint);
}

export const getGroups = async() : Promise<AxiosResponse<GroupGetType[]>> => {
    return await api.get(endpoint);
}

export const getGroup = async(id : number) : Promise<AxiosResponse<GroupDetailsType>> => {
    return await api.get(`${endpoint}/${id}`)
}

export const createGroup = async(data : GroupPostType) : Promise<AxiosResponse<GroupGetType>> => {
    return await api.post(endpoint, data);
}

export const editGroup = async(id : number, data : GroupPostType ) : Promise<AxiosResponse<GroupGetType>> => {
    return await api.put(`${endpoint}/${id}`, data);
}

export const deleteGroup = async(id : number) : Promise<AxiosResponse<GroupGetType>> => {
    return await api.delete(`${endpoint}/${id}`);
}

export const getMembers = async(groupId : number) : Promise<AxiosResponse<GroupMemberType[]>> => {
    return await api.get(`${endpoint}/${groupId}/members`);
}

export const leaveGroup = async(id : number) : Promise<AxiosResponse<ApiSuccessResponse>> => {
    return await api.delete(`${endpoint}/${id}/leave`);
}

export const deleteTravelFromGroup = async(travelId : number, groupId : number) : Promise<AxiosResponse<ApiSuccessResponse>> => {
    return await api.put(`${endpoint}/${groupId}/remove-travel/${travelId}`);
}

export const setPermission = async(permission : PermissionType) : Promise<AxiosResponse<ApiSuccessResponse>> => {
    return await api.put(`${endpoint}/${permission.groupId}/assign-role/${permission.userId}`, {role : permission.role});
}

export const addTravelToGroup = async(travelId : number, groupId : number) : Promise<AxiosResponse<ApiSuccessResponse>> => {
    return await api.put(`${endpoint}/${groupId}/assign-travel/${travelId}`);
}

export const removeMember = async(memberId : number, groupId : number) : Promise<AxiosResponse<ApiSuccessResponse>> => {
    return await api.delete(`${endpoint}/${groupId}/remove-user/${memberId}`);
}

export const inviteUser = async(data : {email : string}, groupId : number) : Promise<AxiosResponse<ApiSuccessResponse>> => {
    return await api.post(`${endpoint}/${groupId}/add-user`, data);
}





