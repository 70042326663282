import { Box, Button, Typography, Grid } from '@mui/material';
import type { FC } from 'react';
import backgroud from '../../assets/images/404.svg';
import { useNavigate } from 'react-router-dom';

interface NotFoundPageProps { }

const NotFoundPage: FC<NotFoundPageProps> = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ width: '100%', height: '100vh', backgroundColor: 'white' }}>
            <Grid container sx={{ height: '100%' }}>
                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', order: { xs: 1, md: 0 } }}>
                    <img src={backgroud} width='100%' alt="404 Not Found" style={{ maxWidth: '100%', height: 'auto' }} />
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '20px' }}>
                    <Typography variant='h1'>
                        Oops!
                    </Typography>
                    <Typography variant='body1' sx={{ padding: '10px 0'}}>
                        Sorry we could not find this page. Press the button below to return to the home page.
                    </Typography>
                    <Button variant='outlined' onClick={() => navigate('/app/dashboard')}>Go Back</Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default NotFoundPage;
