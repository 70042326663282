import { Grid } from '@mui/material';
import {  type FC } from 'react';
import { TravelStatsItemType } from '../../../types/ui/travelStatsItem';
import TravelStatsItem from '../../../components/shared/travelStatsItem/TravelStatsItem';
import SpeedIcon from '@mui/icons-material/Speed';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { useNavContext } from '../../../context/NavContext';
import { durationConventer } from '../../../helpers/durationConventer';

interface TravelStatsProps {
    travelStats: TravelStatsItemType | undefined
}

const TravelStats: FC<TravelStatsProps> = ({ travelStats }) => {
    const { windowSize } = useNavContext();
  
    const isMdBreakpoint = () : boolean => {
        if(windowSize > 1200) return true;
        return false;
    }

    return (
            <>
                {
                    travelStats&&
                    <Grid xs={12} container sx={{height: isMdBreakpoint() ? '140px' : '240px'}}> 
                        <Grid item xs={6} lg={3} sx={{ padding: isMdBreakpoint() ? '0 8px 0 0' : '0 8px 8px 0'}}>
                            <TravelStatsItem
                                label={'Distance'}
                                value={(travelStats?.distance / 1000).toFixed(2)}
                                icon={ModeOfTravelIcon}
                                unit='km'
                            />
                        </Grid>
                        <Grid item xs={6} lg={3} sx={{padding: isMdBreakpoint() ? '0 6px 0 4px' : '0px 0 8px 8px'}}>
                            <TravelStatsItem
                                label={'Avg speed'}
                                value={travelStats?.avgSpeed}
                                icon={SpeedIcon}
                                unit='km/h'
                            />
                        </Grid>
                        <Grid item xs={6} lg={3} sx={{padding: isMdBreakpoint() ? '0 4px 0 6px' : '8px 8px 0 0'}}>
                            <TravelStatsItem
                                label={'duration'}
                                value={durationConventer(travelStats.duration | 0)}
                                icon={HourglassTopIcon}
                                unit=''
                            />
                        </Grid>
                        <Grid item xs={6} lg={3} sx={{padding: isMdBreakpoint() ? '0 0 0 8px' : '8px 0 0 8px'}}>
                            <TravelStatsItem
                                label={'Max speed'}
                                value={travelStats?.maxSpeed}
                                icon={SpeedIcon}
                                unit='km/h'
                            />
                        </Grid>
                    </Grid>
                }
            </>
    );
}

export default TravelStats;
