import type { FC } from 'react';
import { NavItemType } from '../../../types/ui/navItemsType';
import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface NavItemProps {
    navItem: NavItemType
}

const NavItem: FC<NavItemProps> = (props) => {
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();

    const isActive = (): boolean => {
        if (location.pathname.includes(props.navItem.path)) return true;
        return false;
    }

    const onLinkClicked = (): void => {
        navigate(`/app/${props.navItem.path}`);
    }

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '0 8px',
            height: '36px',
            cursor: 'pointer',
            width: '85%',
            borderRadius: '0 20px 20px 0',
            backgroundColor: `${isActive() ? theme.palette.custom.success : 'none'}`,
            margin: '2px 0 0 0',
            borderLeft: isActive() ? '4px solid #25ba9a' : '4px solid white',
            transition: 'all ease-in-out 0.2s',
            '&:hover': {
                backgroundColor: isActive() ? theme.palette.custom.success : theme.palette.custom.success,
                borderLeft: '4px solid #25ba9a', 
            },
        }}
            onClick={onLinkClicked}>
            <Typography
                variant='body2'
                sx={{
                    color: isActive() ? '#22a186' : theme.palette.text.secondary,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 14px',
                    fontSize: '12px',
                    fontWeight: '500',
                    transition: 'all ease-in-out 0.2s',
                    width: '100%',
                    height: '100%',
                    '&:hover': {
                        color: '#22a186', 
                },
                }}
            >
                {React.createElement(props.navItem.icon, { sx: { marginRight: 2, fontSize: '18px' } })}
                {props.navItem.label}
            </Typography>
        </Box>
    );
}

export default NavItem;
