import { Box, Grid, Typography } from '@mui/material';
import type { FC, ReactNode } from 'react';

interface ContentWrapperProps {
    children? : ReactNode;
    pageTitle : string;
}

const ContentWrapper: FC<ContentWrapperProps> = ({children, pageTitle}) => {
    return (
        <Grid container spacing={5} sx={{display: 'flex', justifyContent: 'center'}}>
            <Grid item xs={12}>
                <Typography variant='h4' sx={{ fontWeight: '400' }}>{pageTitle}</Typography>
            </Grid>
            {children}
        </Grid>
    );
}

export default ContentWrapper;
