import { Box, SxProps, Theme } from '@mui/material';
import type { FC, ReactNode } from 'react';

interface ActionDropdownProps {
    children: ReactNode;
    sx?: SxProps<Theme>;
}

const ActionDropdown: FC<ActionDropdownProps> = ({ children, sx }) => {

    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    return (
        <Box
            className='dropdown'
            sx={{
                position: 'absolute',
                backgroundColor: 'white',
                boxShadow: 2,
                borderRadius: '6px',
                pointerEvents: 'auto',
                top: '60px',
                overflow: 'hidden',
                ...sx,
            }}
            onClick={handleClick}>
            {children}
        </Box>
    );
}

export default ActionDropdown;
