import { Box, Typography, useTheme } from '@mui/material';
import type { FC } from 'react';
import { useProfileContext } from '../../../../context/ProfileContext';

interface CommunityStatsProps {}

const CommunityStats: FC<CommunityStatsProps> = () => {
    const theme = useTheme();
    const { profileData } = useProfileContext();

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            borderRadius: '6px',
            boxShadow: 3,
        }}>
            <Box sx={{
                padding: '20px 25px',
                color: theme.palette.text.primary,
                fontWeight: 400,
                fontSize: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: `1px solid ${theme.palette.text.disabled}`,
            }}>
                <Box>Profile stats</Box>
            </Box>
            <Box sx={{
                padding: '20px 0',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'center'
                }}>
                    <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>Followers</Typography>
                    <Typography variant='h2'>{profileData?.followersCount}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'center'
                }}>
                    <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>Following</Typography>
                    <Typography variant='h2'>{profileData?.followingCount}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'center'
                }}>
                    <Typography variant='h6' sx={{ color: theme.palette.text.secondary }}>Friends</Typography>
                    <Typography variant='h2'>{profileData?.friendsCount}</Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default CommunityStats;
