import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

interface AccountConfirmationContextType {
  isAccountCofirmed : number
  setAccountConfirmation : (state : number) => void;
}

const AccountConfirmationContext = createContext<AccountConfirmationContextType | undefined>(undefined);

export const AccountConfirmationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAccountCofirmed, setAccountConfirmation] = useState<number>(0);

    useEffect(() => {
        const accountState : number = Number(localStorage.getItem('isEnabled')) | 0;
        setAccountConfirmation(accountState);
    }, [])

  return (
    <AccountConfirmationContext.Provider value={{ setAccountConfirmation, isAccountCofirmed }}>
      {children}
    </AccountConfirmationContext.Provider>
  );
};

export const useAccountConfirmationContext = () => {
  const context = useContext(AccountConfirmationContext);
  if (!context) {
    throw new Error('useAccountConfirmationContext must be used within an AccountConfirmationContextProvider');
  }
  return context;
};
