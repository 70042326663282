import { useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import type { FC } from 'react';

interface CustomDatePickerProps {
    label: string,
    value: string | undefined,
    onChange : (date : string | undefined) => void;
}

const CustomDatePicker: FC<CustomDatePickerProps> = (props) => {
    const theme = useTheme();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={props.label}
                views={['year', 'month', 'day']}
                value={props.value ? dayjs(props.value) : null}
                onChange={(newValue: Dayjs | null) => {
                    if (newValue) {
                        const formattedDate = newValue.format('YYYY-MM-DD'); 
                        props.onChange(formattedDate);
                    } else props.onChange(undefined); 
                    
                }}
                slotProps={{
                    textField: {
                        sx: {
                            width: '100%',
                            '& .MuiInputBase-input': {
                                fontSize: '14px',
                                fontWeight: 400,
                            },
                            '& .MuiInputLabel-root': {
                                color: theme.palette.primary.main,
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.primary.main
                            },
                            '& .MuiInputAdornment-root .MuiSvgIcon-root': {
                                color: theme.palette.text.primary,
                            },
                        },
                    },
                }}
            />
        </LocalizationProvider>
    );
}

export default CustomDatePicker;
