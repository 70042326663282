import { Box, SvgIconTypeMap, useTheme } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import type { FC } from 'react';
import React from 'react';

interface FrameIconProps {
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

const FrameIcon: FC<FrameIconProps> = (props) => {
    const theme = useTheme();

    return (
        <Box sx={{
            height: '40px', 
            width: '40px', 
            border: `1px solid ${theme.palette.text.disabled}`, 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '100px'}}>
            {React.createElement(props.icon, { sx: { fontSize: '24px', color: theme.palette.primary.main } })}
        </Box>
    );
}

export default FrameIcon;
