import { Box, SxProps, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';

interface HeaderItemProps {
    onClick: () => void; 
    children: ReactNode;  
    sx?: SxProps<Theme>; 
}

const HeaderItem: FC<HeaderItemProps> = ({ onClick, children, sx }) => {
    return (
        <Box
            sx={{
                height: '40px',
                width: '40px',
                backgroundColor: 'white',
                borderRadius: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: 2,
                cursor: 'pointer',
                position: 'relative',
                ...sx, 
            }}
            onClick={onClick}
        >
            {children}
        </Box>
    );
};

export default HeaderItem;