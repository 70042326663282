import { Box, Card, Typography, Alert } from '@mui/material';
import type { FC } from 'react';
import theme from '../../../../core/themes/theme';
import DynamicForm from '../../../../shared/ui/form/DynamicForm';
import background from '../../../../assets/images/background.jpg';
import React, { useEffect } from 'react';
import { useAppContext } from '../../../../core/context/AppContext';
import { LoadingButton } from '@mui/lab';
import { passwordReset, setNewPassword } from '../../../../services/authService';
import { newPasswordFormConfig } from '../../forms/newPasswordFormConfig';
import { PasswordResetType } from '../../types/passwordResetType';
import { useNavigate, useParams } from 'react-router-dom';


interface NewPasswordPageProps {}

const NewPasswordPage: FC<NewPasswordPageProps> = () => {
    const { token } = useParams<{ token: string }>();
    const navigate = useNavigate();

    const {isLoading, error, setError, success} = useAppContext();
    const[formValues, setValues]=React.useState<{password : string, confirmPassword : string}>({
        password : '',
        confirmPassword : ''
    })

    const onFormValuesChange = (data : Partial<{password : string, confirmPassword : string}>) => {
        setValues((prevValue) => ({
            ...prevValue,
            ...data,
        }));
        setError(null);
    };

    const setNewPasswordHandle = async () => {
        const data : PasswordResetType = {
            token : token || '',
            newPassword : formValues.password
        }
        await setNewPassword(data)
            .then(res => {
                navigate('/auth/login')
            })
    };

    useEffect(() => {
        document.title = "FollowMe | New password"; 
    }, [])

    return (
        <Box>
            <Card
                sx={{ width: 360, borderRadius: '10px', boxShadow: '0px 0px 24px -7px rgba(66, 68, 90, 1);' }}
            >
                <Box
                    sx={{
                        height: 200,
                        width: '100%',
                        backgroundImage: `url(${background})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            backgroundColor: '#00000049',
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            padding: '0 28px'
                        }}
                    >
                        <Typography
                            variant="h3"
                            color="white">
                            New Password
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ padding: '40px 28px' }}>
                    <DynamicForm
                        formConfig={newPasswordFormConfig}
                        onChange={onFormValuesChange}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            padding: '10px 0'
                        }}>
                    </Box>
                    <LoadingButton
                    type='submit'
                    variant='contained'
                    color='primary'
                    size='large'
                    fullWidth
                    loading={isLoading}
                    onClick={setNewPasswordHandle}>
                        Submit
                    </LoadingButton>
                </Box>
            </Card>
            {error &&
                <Alert 
                severity="error"
                sx={{position: 'fixed', right: '20px', bottom: '20px', padding: '10px 20px'}}>
                    {error.message}
                </Alert> 
            }
        </Box>
    );
}

export default NewPasswordPage;
