import { Box } from '@mui/material';
import { useEffect, useState, type FC } from 'react';
import { MapContainer, Polyline, TileLayer } from 'react-leaflet';
import MapControls from './mapControls/MapControls';
import { PathType } from '../../../types/api/paths/PathType';

interface MapProps {
    paths: PathType[]; 
}

const SingleTravelMap: FC<MapProps> = ({ paths }) => {
    const [tripCoordinates, setTripCoordinates] = useState<Array<Array<[number, number]>>>([]);

    const setRoutes = () => {
        const coordinates: Array<Array<[number, number]>> = paths.map(path =>
            path.gpsLogs.map(gpsLog => [gpsLog.latitude, gpsLog.longitude] as [number, number])
        );
        setTripCoordinates(coordinates);
    };

    useEffect(() => {
        setRoutes();
    }, [paths]);

    return (
        <Box sx={{ height: '450px', width: '100%', overflow: 'hidden', borderRadius: '6px', boxShadow: 6 }}>
            <MapContainer
                zoom={13}
                scrollWheelZoom
                style={{ height: '100%', width: '100%' }}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {tripCoordinates.map((coordinates, index) => (
                    <Polyline
                        key={index}
                        positions={coordinates} 
                        color="#58d68d"
                        weight={4}
                        opacity={0.8}
                    />
                ))}
                <MapControls tripCoordinates={tripCoordinates} />
            </MapContainer>
        </Box>
    );
}

export default SingleTravelMap;
