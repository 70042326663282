import { useEffect, useState, type FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GroupDetailsType } from '../../types/api/groups/groupDetailsType';
import { editGroup, getGroup } from '../../../../services/groupService';
import ContentWrapper from '../../components/contentWrapper/contentWrapper';
import GroupEditForm, { GroupDetailsForm } from './components/GroupEditForm';
import { Grid } from '@mui/material';
import { GroupPostType } from '../../types/api/groups/GroupPostType';
import GroupTravelList from './components/GroupTravelsList';
import GroupStats from './components/GroupStats';
import GroupMembers from './components/GroupMembers';
import { AxiosError } from 'axios';

interface GroupDetailsPageProps { }

const GroupDetailsPage: FC<GroupDetailsPageProps> = () => {
    const { groupId } = useParams();
    const navigate = useNavigate();

    const [groupDetails, setGroupDetails] = useState<GroupDetailsType | undefined>(undefined);

    const fetchData = async () => {
        await getGroup(Number(groupId))
            .then(res => {
                console.log(res);
                setGroupDetails(res.data);
            })
            .catch((e : AxiosError) => {
                if(e.status === 404) navigate('/not-found')
            })
    }

    const onGroupEdit = async (formValue : GroupDetailsForm) => {
        const editedData : GroupPostType= {
            name : formValue.name,
            description : formValue.description,
        }
        await editGroup(Number(groupId), editedData)
            .then(res => {
               
            });
    } 

    const removeTravel = (id: number) => {
        setGroupDetails(prev => {
            if (!prev) return prev;
            return {
                ...prev,
                travels: prev.travels.filter(travel => travel.id !== id) 
            };
        });
    };

    useEffect(() => {
        document.title = "FollowMe | Group Details"; 
        fetchData();
    }, [])

    return (
        <ContentWrapper pageTitle='Group details'>
            <Grid item xs={12}>
                <GroupStats
                    groupTravels={groupDetails?.travels || []}
                />
            </Grid>
            <Grid item xs={12} xl={6} sx={{zIndex: 3}}>
                {groupDetails &&
                    <GroupEditForm
                        isOwner={groupDetails.isOwner}
                        groupDetails={{
                            name: groupDetails?.name,
                            description: groupDetails?.description,
                            dateCreated: groupDetails?.dateCreated,
                        }}
                        onEdit={(data) => onGroupEdit(data)}
                    />
                }
            </Grid>
            <Grid item xs={12} xl={6} sx={{zIndex: 2}}>
                <GroupMembers
                    isOwner={groupDetails?.isOwner}
                    role={groupDetails?.userRole || ''}
                    groupId={Number(groupId)}
                />
            </Grid>
            <Grid item xs={12} sx={{zIndex: 1}}>
                <GroupTravelList
                    groupId={Number(groupId)}
                    travels={groupDetails?.travels || []}
                    onTravelDelete={removeTravel}
                    onTravelAdd={() => fetchData()}
                    isOwner={groupDetails?.isOwner || false}
                    role={groupDetails?.userRole || ''}
                ></GroupTravelList>
            </Grid>
        </ContentWrapper>
    );
}

export default GroupDetailsPage;
