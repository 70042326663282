export const newPasswordFormConfig = {
    password: {
        label: 'Password',
        fieldType: 'password',
        value: '',
        error: undefined,
        validation: {
            required: true,
            minLength: 8,
        }
    },
    confirmPassword: {
        label: 'Confirm password',
        fieldType: 'password',
        value: '',
        error: undefined,
        validation: {
            required: true,
            minLength: 8,
        }
    },
}