import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import TravvelsPage from './pages/travels/TravelsPage';
import TravelDetails from './pages/travelDetails/TravelDetails';
import GroupPage from './pages/groups/GroupsPage';
import GroupDetailsPage from './pages/groupDetails/GroupDetails';
import ProfilePage from './pages/profile/ProfilePage';
import NotFoundRedirect from '../errors/NotFoundRedirect';

interface UserRoutesProps {}

const UserRoutes: FC<UserRoutesProps> = () => {
    return (
    <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="travels" element={<TravvelsPage />} />
        <Route path="travels/:travelId" element={<TravelDetails />} />
        <Route path="groups" element={<GroupPage />} />
        <Route path="groups/:groupId" element={<GroupDetailsPage />} />
        <Route path="my-profile" element={<ProfilePage />} />
        <Route path='*' element={<NotFoundRedirect/>} ></Route>
    </Routes>
    );
}

export default UserRoutes;
