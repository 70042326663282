import { AxiosResponse } from "axios";
import { TravelsGetType } from "../pages/user/types/api/travels/travelsGetType";
import api from "../api/config";
import { PaginatedTravelsType } from "../pages/user/types/api/travels/paginatedTravels";
import { TravelSortingType } from "../pages/user/types/ui/travelSortingType";
import { TravelDetailsType } from "../pages/user/types/api/travels/travelDetails";
import { PathType } from "../pages/user/types/api/paths/PathType";
import { TravelEditType } from "../pages/user/types/api/travels/travelEditType";
import { ApiSuccessResponse } from "../core/types/successResponseType";

const endpoint = 'travels'

// export const getTravels = async(page : number, filters : string | null, sorting : SortingType | null) : Promise<AxiosResponse<PaginationDataType<TravelsGetType>>> => {
//     return await axiosInstance.get(`${endpoint}?page=${page}&limit=5${filters ? `&${filters}` : ''}${sorting ? `&sortField=${sorting.sortField}&sortOrder=${sorting.sortOrder}` : ''}`);
// }

export const getTravels = async(page : number, filters : string | undefined, sorting : TravelSortingType | undefined) : Promise<AxiosResponse<PaginatedTravelsType>> => {
    const sortingUrl = `&sortField=${sorting?.sortField}&sortOrder=${sorting?.sortOrder}`; 
    return await api.get(`${endpoint}?page=${page}&limit=5${filters ? `&${filters}` : ''}${sorting?.sortOrder ? sortingUrl : ''}`);
}


export const getTravelTypes = async() : Promise<AxiosResponse<{id : number, name : string}[]>> => {
    return await api.get(`${endpoint}/types`);
}

export const getTravelDetails = async(travelId : number) : Promise<AxiosResponse<TravelDetailsType>> => {
    return await api.get(`${endpoint}/${travelId}`);
}

export const editTravelDetails = async(id:number, data : TravelEditType) : Promise<AxiosResponse<TravelDetailsType>> => {
    return await api.put(`${endpoint}/${id}`, data);
}

export const getTravelsPaths = async(travelId : number) : Promise<AxiosResponse<PathType[]>> => {
    return await api.get(`${endpoint}/${travelId}/gpslogs`);
}

export const deleteTravel = async(travelId : number) : Promise<AxiosResponse<ApiSuccessResponse>> => {
    return await api.delete(`${endpoint}/${travelId}`);
}

export const getRecentTravels = async() : Promise<AxiosResponse<TravelsGetType[]>> => {
    return await api.get(`${endpoint}/recent?limit=5`);
}

export const getTravelsByTravelName = async(searchParams : string) : Promise<AxiosResponse<PaginatedTravelsType>> => {
    return await api.get(`${endpoint}?limit=10&name=${searchParams}`);
}