import { Avatar, Box, useTheme } from '@mui/material';
import { type FC } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavContext } from '../../context/NavContext';
import HeaderItem from './headerItem/headerItem';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import LoggedUser from './loggedUser/LoggedUser';
import { useDropdownContext } from '../../../../core/context/DropdownContext';
import ActionDropdown from '../actionDropdown/ActionDropdown';
import { useProfileContext } from '../../context/ProfileContext';

interface HeaderProps { }

const Header: FC<HeaderProps> = () => {
    const { profileData } = useProfileContext();
    const theme = useTheme();
    const { setMenuState } = useNavContext();
    const { openDropdown, setOpenDropdown } = useDropdownContext();

    return (
        <Box sx={{
            width: '100%',
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
        }}>
            <Box sx={{
                width: '90%',
                height: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
            >
                <HeaderItem onClick={() => setMenuState(true)}>
                    <MenuIcon sx={{
                        fontSize: '24px',
                        color: theme.palette.text.secondary,
                        '&:hover': {
                            color: theme.palette.text.primary,
                        }
                    }} />
                </HeaderItem>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100px'
                }}
                >
                    <HeaderItem onClick={() => console.log('xd')}>
                        <NotificationsActiveIcon sx={{
                            fontSize: '24px',
                            color: theme.palette.text.secondary,
                            '&:hover': {
                                color: theme.palette.text.primary,
                            }
                        }} />
                    </HeaderItem>
                    <HeaderItem onClick={() => setOpenDropdown('profileHeader')}>
                        <Avatar
                            alt="header-pic"
                            src={profileData?.profilePic}
                        />
                        {openDropdown === 'profileHeader' &&
                        <ActionDropdown> 
                            <LoggedUser/>
                        </ActionDropdown>
                        }
                    </HeaderItem>
                </Box>
            </Box>
        </Box>
    );
}

export default Header;
