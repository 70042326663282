import { AxiosResponse } from "axios";
import api from "../api/config";
import { LoginType } from "../pages/auth/types/loginType";
import { RegisterType } from "../pages/auth/types/registerType";
import { ApiSuccessResponse } from "../core/types/successResponseType";
import { PasswordResetType } from "../pages/auth/types/passwordResetType";
import { LoggedUserType } from "../pages/auth/types/loggedUserType";

export const login = async(loginData : LoginType) : Promise<AxiosResponse<LoggedUserType>> => {
    return await api.post(`login`, loginData);
}

export const register = async(registerData : RegisterType) : Promise<AxiosResponse<ApiSuccessResponse>> => {
    return await api.post(`register`, registerData)
}

export const passwordReset = async(data : {email : string}) : Promise<AxiosResponse<ApiSuccessResponse>> => {
    return await api.post(`forgot-password`, data)
}

export const setNewPassword = async(data : PasswordResetType) : Promise<AxiosResponse<ApiSuccessResponse>> => {
    const convertedData : { newPassword : string } = {newPassword : data.newPassword};
    return await api.post(`reset-password/${data.token}`, convertedData);
}

export const resendConfirmationToken = async() : Promise<AxiosResponse<ApiSuccessResponse>> => {
    return await api.post('resend-confirmation-email');
}

export const confirmAccount = async(token : string) : Promise<AxiosResponse<ApiSuccessResponse>> => {
    return await api.post(`confirm-account/${token}`);
}

// export const sendDeleteAccountToken = async() : Promise<AxiosResponse<ApiSuccessResponse>> => {
//     return await axiosInstance.post(`${serviceUrl}delete-account`);
// }

// export const deleteAccount = async(token : string) : Promise<AxiosResponse<ApiSuccessResponse>> => {
//     return await axiosInstance.delete(`${serviceUrl}delete-account/${token}`);
// }
