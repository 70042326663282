import axios from 'axios';
import { ErrorType } from '../core/types/error-type';
import { ApiSuccessResponse } from '../core/types/successResponseType';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
});

export const setupInterceptors = (
  setIsLoading: (loading: boolean) => void,
  setError: (error: ErrorType | null) => void,
  setSuccess: (success: ApiSuccessResponse | null) => void
) => {
  api.interceptors.request.use(
    (config) => {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      if (token) config.headers.Authorization = `Bearer ${token}`;

      if (config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
      } else if (typeof config.data === 'object') {
        config.headers['Content-Type'] = 'application/json';
        config.data = JSON.stringify(config.data); 
      }

      return config;
    },
    (error) => {
      setIsLoading(false);
      setError({ status: 500, message: 'Request error' });
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      setIsLoading(false);
      setSuccess(response.data.status || response.data.message);
      setError(null);
      return response;
    },
    (error) => {
      setIsLoading(false);
      setSuccess(null);
      console.log(error);
      if (error.response) {
        setError({
          status: error.response.status,
          message: error.response.data.error,
        });
      } else setError({ status: 500, message: 'Server error' });
      errorRedirect(error.response.status);
      return Promise.reject(error);
    }
  );
};

const errorRedirect = (statusCode : number) => {
  switch(statusCode){
    case 500:
      console.log('xd') 
      // window.location.replace('/server-error');
      break;
    case 401:
     
      // window.location.replace('/auth/login');
  }
}

export default api;