import { FormFieldType } from "../../../shared/types/ui/formConfigType";

export const addGroupFormConfig: FormFieldType = {
    name: {
        label: 'Group name',
        fieldType: 'text',
        value: '',
        error: undefined,
        validation: {
            required: true,
        }
    },
    description: {
        label: 'Description',
        fieldType: 'textarea',
        value: '',
        error: undefined,
        validation: {
            required: true,
        }
    }
}