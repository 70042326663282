import { Box, CircularProgress } from '@mui/material';
import type { FC } from 'react';

interface LoaderProps {}

const Loader: FC<LoaderProps> = () => {
    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            minHeight: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            left: 0,
            top: 0,
        }}
        >
            <CircularProgress size={60} />
        </Box>
    );
}

export default Loader;
