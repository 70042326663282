import { Box, Grid, Typography, Button } from '@mui/material';
import { useEffect, type FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import backgroud from '../../../../assets/images/Backgroud.svg';
import { useAccountConfirmationContext } from '../../../../core/context/AccountConfirmationContext';
import { confirmAccount } from '../../../../services/authService';

interface AccountConfirmationPageProps {}

const AccountConfirmationPage: FC<AccountConfirmationPageProps> = () => {
    const {token} = useParams();
    const navigate = useNavigate();
    const { setAccountConfirmation } = useAccountConfirmationContext();
    
    useEffect(() => {
        onConfirmAccount();
    }, [])

    const onConfirmAccount = async() => {
       await confirmAccount(token || '')
        .then(res => {
            localStorage.setItem('isEnabled', '1');
            setAccountConfirmation(1);
        })
        .catch(e => {
            navigate('/not-found');
        })
    }

    return (
        <Box sx={{ width: '100%', height: '100vh', backgroundColor: 'white' }}>
        <Grid container sx={{ height: '100%' }}>
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', order: { xs: 1, md: 0 } }}>
                <img src={backgroud} width='100%' alt="404 Not Found" style={{ maxWidth: '100%', height: 'auto' }} />
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: '20px' }}>
                <Typography variant='h1'>
                    Success!
                </Typography>
                <Typography variant='body1' sx={{ padding: '10px 0'}}>
                    Your account is confirmed, now you can use FollowMe
                </Typography>
                <Button variant='outlined' onClick={() => navigate('/app/dashboard')}>Follow Me</Button>
            </Grid>
        </Grid>
    </Box>
    );
}

export default AccountConfirmationPage;
