import { ListItem, Checkbox, ListItemButton, ListItemText, Typography } from '@mui/material';
import { getTravelsByTravelName } from '../../../../../services/travelsService';
import { useParams } from 'react-router-dom';
import { addTravelToGroup } from '../../../../../services/groupService';
import ListDropdown from '../../../components/listDropdown/ListDropdown';
import { format } from 'date-fns';
import { dateFormat } from '../../../../../core/helpers/dateFormat';
import theme from '../../../../../core/themes/theme';

const AddTravelToGroup = ({ onTravelsAdd }: { onTravelsAdd: () => void }) => {
    const { groupId } = useParams();

    const fetchTravels = async (search: string) => {
        const res = await getTravelsByTravelName(search);
        return res.data.paginatedData;
    };

    const submitTravels = async (selectedIds: number[]) => {
        for (const travelId of selectedIds) {
            await addTravelToGroup(travelId, Number(groupId));
        }
        onTravelsAdd();
    };

    const renderTravelItem = (travel: any, isSelected: boolean, toggle: (id: number) => void) => (
        <ListItem key={travel.id} secondaryAction={<Checkbox edge="end" onChange={() => toggle(travel.id)} checked={isSelected} />}>
            <ListItemButton>
                <ListItemText sx={{ width: '50%' }}>
                    <Typography variant='body2'>{travel.name}</Typography>
                </ListItemText>
                <ListItemText>
                    <Typography variant='body2' sx={{ fontWeight: 300, color: theme.palette.text.secondary, fontSize: '12px' }}>{format(travel.dateCreated, dateFormat)}</Typography>
                </ListItemText>
            </ListItemButton>
        </ListItem>
    );

    return (
        <ListDropdown
            titlePlaceholder="Travel name"
            fetchItems={fetchTravels}
            onSubmit={submitTravels}
            renderItem={renderTravelItem}
            noDataMessage="No trips"
        />
    );
};

export default AddTravelToGroup;
