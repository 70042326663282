import { Slider, Typography, useTheme } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect } from 'react';

interface DistanceSliderProps {
    onChangeValue: (range: number[]) => void;
    minValue: number,
    maxValue: number,
}

const DistanceSlider: FC<DistanceSliderProps> = (props) => {
    const theme = useTheme();
    const [value, setValue] = React.useState<number[]>([props.minValue, props.maxValue]);
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number[]);
        props.onChangeValue(value);
    };

    useEffect(() => {
        setValue([props.minValue, props.maxValue]);
    }, [props.maxValue, props.maxValue])

    return (
        <>
            <Slider
                getAriaLabel={() => 'Temperature range'}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                min={0}
                max={1000}
                step={50}
                sx={{
                    height: '20px',
                    '& .MuiSlider-thumb': {
                        height: '24px',
                        width: '24px',
                        backgroundColor: theme.palette.primary.main,
                        border: '4px solid white',
                        '&:focus, &:hover, &.Mui-active': {
                            boxShadow: 'inherit',
                        },
                    },
                    '& .MuiSlider-track': {
                        backgroundColor: theme.palette.primary.light,
                        border: 'none',
                    },
                    '& .MuiSlider-rail': {
                        backgroundColor: '#60ffddc2',
                        border: 'none',
                    }
                }}
            />
            <Typography
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '18px',
                    fontWeight: '500'
                }}
                variant='body1'>
                {value[0]} - {value[1]} km
            </Typography>
        </>
    );
}

export default DistanceSlider;
