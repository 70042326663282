import { Grid } from '@mui/material';
import type { FC } from 'react';
import SpeedIcon from '@mui/icons-material/Speed';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { useNavContext } from '../../../context/NavContext';
import TravelStatsItem from '../../../components/shared/travelStatsItem/TravelStatsItem';

interface LastTravelStatsProps{
    tableHeight: number;
}

const LastTravelStats: FC<LastTravelStatsProps> = (props) => {
    const { windowSize } = useNavContext();
    
    const isMdBreakpoint = () : boolean => {
        if(windowSize > 900 && windowSize < 1536) return true;
        return false;
    }

    const setHeight = () : number => {
        if(isMdBreakpoint()) return 180;
        if(windowSize <= 900) return 360; 
        return props.tableHeight;
    }

    return (
        <Grid container sx={{height: `${setHeight()}px`}}>
            <Grid 
                xs={6} 
                md={3} 
                xl={6} 
                sx={{ padding: isMdBreakpoint() ? '0 8px 0 8px' : '0 8px 8px 0'}}>
                <TravelStatsItem 
                    label='Distance'
                    value='30'
                    unit='km'
                    icon={ModeOfTravelIcon}
                />
            </Grid>
            <Grid xs={6} md={3} xl={6} sx={{padding: isMdBreakpoint() ? '0 8px 0 8px' : '0 0 8px 8px'}}>
                <TravelStatsItem
                    label='Max speed'
                    value='80'
                    unit='km/h'
                    icon={SpeedIcon}
                />
            </Grid>
            <Grid xs={6} md={3} xl={6} sx={{padding: isMdBreakpoint() ? '0 8px 0 8px' : '8px 8px 0 0'}}>
                <TravelStatsItem
                    label='Avg speed'
                    value='30'
                    unit='km/h'
                    icon={SpeedIcon}
                />
            </Grid>
            <Grid xs={6} md={3} xl={6} sx={{padding: isMdBreakpoint() ? '0 0 0 8px' : '8px 0 0 8px'}}>
                <TravelStatsItem
                    label='Duration'
                    value='01:00:00'
                    unit=''
                    icon={HourglassTopIcon}
                />
            </Grid>
        </Grid>
    );
}

export default LastTravelStats;
