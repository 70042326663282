import { createTheme, PaletteOptions } from '@mui/material/styles';

// Rozszerz typy, aby dodać "custom" do Palette i PaletteOptions
declare module '@mui/material/styles' {
    interface Palette {
        custom: {
            success: string;
            error: string;
            warning: string;
            info: string;
        };
    }
    interface PaletteOptions {
        custom?: {
            success: string;
            error: string;
            warning: string;
            info: string;
        };
    }
}

const theme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontWeight: '400', verticalAlign: 'middle', padding: '20px' 
                }
            }
        }
    },
    palette: {
        primary: {
            main: '#25ba9a',
            light: '#2dddb7',
            contrastText: '#ffffff',
        },
        custom: {
            success: '#d9f5f0',
            error: '#f44336',
            warning: '#ff9800',
            info: '#2196f3',
        },
        background: {
            default: '#f4fbfa',
            paper: '#ffffff',
        },
        text: {
            primary: '#212121',  
            secondary: '#686868', 
            disabled: '#d6d6d6',  
        },
    },
    typography: {
        fontFamily: '"Poppins", "Arial", sans-serif',  // Domyślna czcionka
        h1: {
            fontSize: '2.5rem',
            fontWeight: 500,
            letterSpacing: '0.05em',
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 400,
        },
        h3: {
            fontSize: '1.75rem',
            fontWeight: 300,
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: 300,
        },
        h5: {
            fontSize: '1.25rem',
            fontWeight: 400,
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 500,
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 400,
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 500,
        },
        button: {
            fontSize: '0.875rem',
            fontWeight: 500,
            textTransform: 'uppercase',
            letterSpacing: 1.5,  
        },
        caption: {
            fontSize: '0.75rem',
            fontWeight: 300,
        },
        overline: {
            fontSize: '0.625rem',
            fontWeight: 500,
            textTransform: 'uppercase',
        },
    },
});

export default theme;