export const permissions : string[] = [
    'Owner', 'Admin', 'Member', 'View only' 
]

export const canDeleteGroup = (isOwner : boolean) : boolean => {
    return isOwner;
}

export const canInviteUsers = (isOwner : boolean, role : string) : boolean => {
    return isOwner || role === "ADMIN";
}

export const canRemoveUsers = (isOwner : boolean, role : string, loggedUserId : number, userId : number) : boolean => {
    return (isOwner || role === "ADMIN") && loggedUserId !== userId;
} 

export const canSetPermissions = canRemoveUsers;

export const canAddTravel = (isOwner : boolean, role : string) : boolean => {
    return isOwner || role === "ADMIN" || role === "MEMBER";
}

export const canRemoveTravels = (isOwner : boolean, role : string, loggedUserId : number, travelOwnersId : number) : boolean => {
    if(isOwner || role === "ADMIN") return true;
    if(role === "MEMBER" && loggedUserId === travelOwnersId) return true;
    return false;
} 