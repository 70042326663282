import { type FC } from 'react';
import { Outlet } from 'react-router-dom';
import Nav from './components/nav/Nav';
import { Alert, Box } from '@mui/material';
import Header from './components/header/Header';
import { useNavContext } from './context/NavContext';
import Footer from './components/footer/Footer';
import { useAppContext } from '../../core/context/AppContext';
import { EmailSelectionProvider } from './pages/groupDetails/context/EmailSelectionContext';
import { ProfileContextProvider } from './context/ProfileContext';
import NoActiveAccount from './components/noActiveAccount/NoActiveAccount';
import { useAccountConfirmationContext } from '../../core/context/AccountConfirmationContext';

interface UserLayoutProps { }

const UserLayout: FC<UserLayoutProps> = () => {
    const { error, setError, success, setSuccess } = useAppContext();
    const { isMenuOpen, windowSize } = useNavContext();
    const { isAccountCofirmed } = useAccountConfirmationContext();

    return (
        <ProfileContextProvider>
                <EmailSelectionProvider>
                    {isAccountCofirmed !== 1 && <NoActiveAccount />}
                    <Box sx={{ width: '100%', overflow: 'hidden' }}>
                        <Box sx={{
                            width: '100%',
                            minHeight: 'calc(100vh - 121px)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            margin: 0,
                            padding: 0,
                            position: 'relative'
                        }}>
                            <Header />
                            <Nav />
                            <Box sx={{
                                width: isMenuOpen && windowSize >= 992 ? 'calc(100% - 240px)' : '100%',
                                transition: 'all ease-in-out 0.2s',
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                <Box sx={{
                                    width: '90%',
                                }}>
                                    <Outlet />
                                </Box>
                            </Box>
                            {error &&
                                <Alert
                                    onClick={() => setError(null)}
                                    severity="error"
                                    sx={{ position: 'fixed', right: '20px', top: '20px', padding: '10px 20px', zIndex: '100', cursor: 'pointer' }}>
                                    {error.message}
                                </Alert>
                            }
                             {success &&
                                <Alert
                                    onClick={() => setSuccess(null)}
                                    severity="success"
                                    sx={{ position: 'fixed', right: '20px', top: '20px', padding: '10px 20px', zIndex: '100', cursor: 'pointer' }}>
                                    {success.status}
                                </Alert>
                            }
                        </Box>
                        <Footer />
                    </Box>
                </EmailSelectionProvider>
        </ProfileContextProvider>
    );
}

export default UserLayout;
