import { Alert, Box, Grid, TextField } from '@mui/material';
import { useState, type FC } from 'react';
import FrameIcon from '../../../../../shared/ui/frameIcon/FrameIcon';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../../../../core/context/AppContext';
import { format } from 'date-fns';
import { dateFormat } from '../../../../../core/helpers/dateFormat';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { deleteGroup, leaveGroup } from '../../../../../services/groupService';
import { ActionItemType } from '../../../types/ui/actionItemType';
import Action from '../../../components/actionDropdown/Action';
import LogoutIcon from '@mui/icons-material/Logout';
import { canDeleteGroup } from '../permisions';

interface GroupEditFormProps {
    isOwner : boolean,
    groupDetails: GroupDetailsForm
    onEdit: (formValues: GroupDetailsForm) => void,
}

export interface GroupDetailsForm {
    name: string,
    description: string,
    dateCreated: Date,
}

const GroupEditForm: FC<GroupEditFormProps> = ({ groupDetails, onEdit, isOwner }) => {
    const navigate = useNavigate();
    const { groupId } = useParams();
    const { isLoading } = useAppContext();
    const [formValues, setFormValues] = useState<GroupDetailsForm>(groupDetails);

    const onGroupDelete = async () => {
        await deleteGroup(Number(groupId))
            .then(res => {
                navigate('/app/groups');
            })
    }

    const onGroupLeave = async () => {
        await leaveGroup(Number(groupId))
            .then(res => {
                navigate('/app/groups')
            })
    }

    const ownerActions: ActionItemType[] = [
        {
            label: 'Delete group',
            icon: DeleteForeverIcon,
            onAction: async() => onGroupDelete()
        },
        {
            label: 'Leave group',
            icon: LogoutIcon,
            onAction: async(groupId) => onGroupLeave()
        }
    ]

    const otherActions: ActionItemType[] = [
        {
            label: 'Leave group',
            icon: LogoutIcon,
            onAction: async(groupId) => onGroupLeave()
        }
    ]

    return (
        <Grid item xs={12} sx={{ bgcolor: 'white' }}>
            <Box sx={{
                width: '100%',
                backgroundColor: 'white',
                borderRadius: '6px',
                boxShadow: 3,
                minHeight: '400px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative'
            }}>
                <Box sx={{ position: 'absolute', right: '20px', top: '14px', cursor: 'pointer' }}>
                    <Action
                        actionName='groupDetailsAction'
                        actionItems={canDeleteGroup(isOwner) ? ownerActions : otherActions}
                    />
                </Box>
                <Box sx={{
                    width: '85%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 0 20px 0',
                }}>
                    <FrameIcon
                        icon={StickyNote2Icon}
                    />
                    <TextField
                        disabled={isLoading}
                        id="standard-basic"
                        label="Group name"
                        variant="standard"
                        value={formValues.name}
                        onBlur={() => onEdit(formValues)}
                        onChange={(event) => {
                            setFormValues(prev => ({
                                ...prev,
                                name: event.target.value
                            }))
                        }}
                        sx={{
                            width: '90%',
                            marginLeft: '40px'
                        }} />
                </Box>
                <Box sx={{
                    width: '85%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '20px 0 20px 0',
                }}>
                    <FrameIcon
                        icon={DescriptionIcon}
                    />
                    <TextField
                        disabled={isLoading}
                        id="standard-basic"
                        label="Description"
                        variant="standard"
                        multiline
                        rows={3}
                        value={formValues.description}
                        onBlur={() => onEdit(formValues)}
                        onChange={(event) => {
                            setFormValues(prev => ({
                                ...prev,
                                description: event.target.value
                            }))
                        }}
                        sx={{
                            width: '90%',
                            marginLeft: '40px'
                        }} />
                </Box>
                <Box sx={{
                        width: '85%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '20px 0 0 0',
                    }}>
                        <FrameIcon
                            icon={EventAvailableIcon}
                        />
                        <TextField
                            id="standard-basic"
                            label="Group created"
                            variant="standard"
                            value={format(new Date(formValues.dateCreated), dateFormat)}
                            onChange={(event) => {
                                setFormValues((prev) => ({
                                    ...prev,
                                    description: event.target.value
                                }));
                            }}
                            disabled={true}
                            sx={{
                                width: '90%',
                                marginLeft: '40px',
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: "#000000",
                                },
                                '& .MuiInput-underline:before': {
                                    borderBottom: 'none',
                                },
                                '& .MuiInputLabel-root.Mui-disabled': {
                                    color: '#686868',
                                },
                            }} />
                    </Box>
            </Box>
        </Grid>
    );
}

export default GroupEditForm;
