import { Box } from '@mui/material';
import { useEffect, useState, type FC } from 'react';
import { TravelsGetType } from '../../../types/api/travels/travelsGetType';
import path from 'path';
import { MapContainer, Polyline, TileLayer } from 'react-leaflet';
import MapControls from './mapControls/MapControls';

interface MapProps {
    travels: TravelsGetType[]
}

const Map: FC<MapProps> = ({ travels }) => {
    const [tripCoordinates, setTripCoordinates] = useState<Array<Array<[number, number]>>>([]);

    const setRoutes = () => {
        const coordinates = travels.flatMap(travel =>
            travel.paths.map(path =>
                path.gpsLogs.map(gpsLog => [gpsLog.latitude, gpsLog.longitude] as [number, number])
            )
        );
        setTripCoordinates(coordinates);
    };

    useEffect(() => {
        setRoutes();
    }, [travels]);

    return (
        <Box sx={{height: '450px', widoth: '100%', overflow: 'hidden', borderRadius: '6px', boxShadow: 6}}>
            <MapContainer
                zoom={13}
                scrollWheelZoom
                style={{ height: '100%', width: '100%' }}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {tripCoordinates.map((coordinates, index) => (
                    <Polyline
                        key={index}
                        positions={coordinates}
                        color="#58d68d"
                        weight={4}
                        opacity={0.8}
                    />
                ))}
                <MapControls tripCoordinates={tripCoordinates} />
            </MapContainer>
        </Box>
    );
}

export default Map;
