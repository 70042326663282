import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import type { FC } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { useAppContext } from '../../../../../../core/context/AppContext';

interface NoDataComponentProps { 
    size: number,
    title: string,
    details : string,
}

const NoDataComponent: FC<NoDataComponentProps> = (props) => {
    const theme = useTheme();
    const { isLoading } = useAppContext();

    return (
        <Box sx={{
            width: '100%',
            height: `${400 / props.size}px`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            {!isLoading ? 
                <>
                    <ErrorIcon sx={{ fontSize: `${100 / props.size}px`, color: theme.palette.primary.main, marginBottom: '20px' }} />
                    <Typography variant='h2'>{props.title}</Typography>
                    <Typography variant='body1' sx={{ color: theme.palette.text.secondary }}>{props.details}</Typography>
                </>
                : 
                <CircularProgress size={60} />
            }
        </Box>
    );
}

export default NoDataComponent;
