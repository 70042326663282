import React, { useState } from 'react';
import { FormFieldType } from '../../types/ui/formConfigType';
import { TextField, useTheme } from '@mui/material';

interface DynamicFormProps {
  formConfig: FormFieldType;
  onChange: (data: Record<string, string | number>) => void;  // Funkcja, która zostanie wywołana po zmianie
}

const DynamicForm: React.FC<DynamicFormProps> = (props) => {
  const theme = useTheme();
  const [fields, setFields] = useState(props.formConfig);

  const handleChange = (key: string, value: string | number) => {
    setFields((prevFields) => {
      const updatedFields = {
        ...prevFields,
        [key]: {
          ...prevFields[key],
          value,
          error: '',
        },
      };

      const validatedFields = validateFields(updatedFields);
      const dataToSend = Object.keys(validatedFields).reduce((data, fieldKey) => {
        data[fieldKey] = validatedFields[fieldKey].value;
        return data;
      }, {} as Record<string, string | number>);

      props.onChange(dataToSend);

      return validatedFields;
    });
  };

  const validateFields = (fields: FormFieldType) => {
    const validatedFields = { ...fields };

    Object.keys(validatedFields).forEach((key) => {
      const field = validatedFields[key];

      if (field.validation?.required && !field.value) {
        field.error = `${field.label} is required`;
      } else {
        field.error = '';
      }
    });

    return validatedFields;
  };

  return (
    <form>
      {Object.keys(fields).map((key) => {
        const field = fields[key];

        return (
          <div key={key}>
            <TextField
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: '0.875rem',  
                  color: `${theme.palette.text.secondary}`,
                  fontWeight: 400,  
                },
              }}
              fullWidth
              rows={field.fieldType === 'textarea' ? 5 : 1}
              multiline={field.fieldType === 'textarea' ? true : false}
              label={field.label}
              type={field.fieldType}
              value={field.value || ''}
              onChange={(e) => handleChange(key, e.target.value)}
              error={!!field.error}
              helperText={field.error || ''}
              margin="normal"
            />
          </div>
        );
      })}
    </form>
  );
};

export default DynamicForm;
