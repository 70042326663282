import { GroupTravelType } from "../types/api/travels/groupTravelType";

export interface TravelStats {
    maxSpeed: number;
    avgSpeed: number;
    distance: number;
    duration: number;
}

export const calculateTravelStats = (groupTravels: GroupTravelType[]): TravelStats => {
    if (!groupTravels || groupTravels.length === 0) {
        return {
            maxSpeed: 0,
            avgSpeed: 0,
            distance: 0,
            duration: 0,
        };
    }

    const totalDistance = groupTravels.reduce((sum, travel) => sum + (travel.distance || 0), 0);
    const totalDuration = groupTravels.reduce((sum, travel) => sum + (travel.duration || 0), 0);
    const maxSpeed = Math.max(...groupTravels.map(travel => travel.maxSpeed || 0));
    const avgSpeed = totalDistance && totalDuration ? (totalDistance / (totalDuration / 3600)) : 0; 

    return {
        maxSpeed,
        avgSpeed,
        distance: totalDistance,
        duration: totalDuration,
    };
}