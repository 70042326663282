import { FormFieldType } from "../../../shared/types/ui/formConfigType";

export const loginFormConfig: FormFieldType = {
    email: {
        label: 'Email',
        fieldType: 'text',
        value: '',
        error: undefined,
        validation: {
            required: true,
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        }
    },
    password: {
        label: 'Password',
        fieldType: 'password',
        value: '',
        error: undefined,
        validation: {
            required: true,
            minLength: 8,
        }
    }
}