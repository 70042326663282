import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

interface NavContextType {
  isMenuOpen: boolean;
  windowSize : number;
  setMenuState: (state: boolean) => void;
}

const NavContext = createContext<NavContextType | undefined>(undefined);

export const NavContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isMenuOpen, setMenuState] = useState<boolean>(true);
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
      if (window.innerWidth < 992) setMenuState(false);
      else setMenuState(true);
      
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <NavContext.Provider value={{ isMenuOpen, setMenuState, windowSize }}>
      {children}
    </NavContext.Provider>
  );
};

export const useNavContext = () => {
  const context = useContext(NavContext);
  if (!context) {
    throw new Error('useNavContext must be used within a NavContextProvider');
  }
  return context;
};