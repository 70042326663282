import React, { createContext, useState, useContext, ReactNode } from 'react';
import { ErrorType } from '../types/error-type';
import { ApiSuccessResponse } from '../types/successResponseType';

interface AppContextType {
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  error: ErrorType | null,
  setError: (error: ErrorType | null) => void;
  success: ApiSuccessResponse | null,
  setSuccess: (status : ApiSuccessResponse | null) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType | null>(null);
  const [success, setSuccess] = useState<ApiSuccessResponse | null>(null);

  return (
    <AppContext.Provider value={{ isLoading, setIsLoading, error, setError, success, setSuccess}}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) throw new Error('useAppContext must be used within an AppProvider');
  return context;
};
