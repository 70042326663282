import { TableRow, TableCell, Box, Avatar, Typography, Button } from '@mui/material';
import type { FC } from 'react';
import { dateFormat } from '../../../../../core/helpers/dateFormat';
import theme from '../../../../../core/themes/theme';
import { canRemoveTravels } from '../permisions';
import { GroupTravelType } from '../../../types/api/travels/groupTravelType';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { format } from 'date-fns';

interface GroupTravelsRowProps { 
    travel : GroupTravelType;
    isOwner : boolean,
    role : string,
    onDeleteClick : (travelId : number) => void;
}

const GroupTravelsRow: FC<GroupTravelsRowProps> = ({travel, isOwner, role, onDeleteClick}) => {
    const loggedUserId : number = Number(localStorage.getItem('userId'));

    return (
        <TableRow
            key={travel.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { cursor: 'pointer' } }}
        >
            <TableCell align='left' component="th" scope="row">
                <Box sx={{ display: 'flex' }}>
                    <Avatar src={travel.user.profilePic}></Avatar>
                    <Box sx={{ margin: '0 0 0 20px' }}>
                        <Typography variant='body2'>{travel.user.username}</Typography>
                        <Typography variant='body2' sx={{ fontWeight: 300, color: theme.palette.text.secondary, fontSize: '12px' }}>{travel.user.email}</Typography>
                    </Box>
                </Box>
            </TableCell>
            <TableCell>{travel.name}</TableCell>
            <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <EventAvailableIcon sx={{ margin: '0 10px 0 0', color: theme.palette.primary.main }} />
                    {format(new Date(travel.travelDate), dateFormat)}
                </Box>
            </TableCell>
            <TableCell align='center'>
                {canRemoveTravels(isOwner, role, loggedUserId, travel.user.id) &&
                    <Button color='error' onClick={() => onDeleteClick(travel.id)}>
                        <DeleteForeverIcon />
                    </Button>
                }
            </TableCell>
        </TableRow>
    );
}

export default GroupTravelsRow;
