import {  Grid } from '@mui/material';
import { type FC } from 'react';
import ContentWrapper from '../../components/contentWrapper/contentWrapper';
import PersonalData from './components/personalData/PersonalData';
import ProfileDetails from './components/profileDetails/ProfileDetails';
import AuthData from './components/authData/AuthData';
import CommunityStats from './components/communityStats/CommunityStats';

interface ProfilePageProps { }

const ProfilePage: FC<ProfilePageProps> = () => {
    return (
        <ContentWrapper pageTitle='My profile'>
            <Grid item container spacing={3} xs={12}>
                <Grid item container xs={12} md={6} xl={4}>
                    <PersonalData/>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <ProfileDetails/>
                </Grid>
                <Grid item container spacing={3} xs={12} xl={4}>
                    <Grid item xs={12} md={6} xl={12}>
                        <AuthData/>
                    </Grid>
                    <Grid item xs={12} md={6} xl={12} sx={{minHeight: '40%'}}>
                        <CommunityStats/>
                    </Grid>
                </Grid>
            </Grid>
        </ContentWrapper>
    );
}

export default ProfilePage;
