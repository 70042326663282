import { TravelSortingType } from "../../../types/ui/travelSortingType";
import { TravelFiltersType } from "../types/travelFiltersType";

export const convertFiltersToUrl = (filters: TravelFiltersType, sorting : TravelSortingType | undefined): string => {
    const params = new URLSearchParams();

    const filterMappings: Record<string, string | number | null | undefined | Date> = {
        minDistance: filters.minDistance !== undefined ? filters.minDistance * 1000 : 0, 
        maxDistance: filters.maxDistance !== undefined? filters.maxDistance * 1000 : 0,
        minTravelDate: filters.minTravelDate ? filters.minTravelDate : undefined,
        maxTravelDate: filters.maxTravelDate ? filters.maxTravelDate : undefined,
        travelType: filters.travelType !== null ? filters.travelType : null,
        travelGroup: filters.travelGroup !== null ? filters.travelGroup : null,
        sortField: sorting?.sortField !== undefined || null ? sorting?.sortField : null,
        sortOrder: sorting?.sortOrder !== undefined || null ? sorting?.sortOrder : null,
    };

    Object.entries(filterMappings).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== '') { 
            params.append(key, String(value)); 
        }
    });

    return params.toString(); 
};