import { Box, Button, TextField, useTheme } from '@mui/material';
import type { FC } from 'react';
import FrameIcon from '../../../../../../shared/ui/frameIcon/FrameIcon';
import CelebrationIcon from '@mui/icons-material/Celebration';
import DescriptionIcon from '@mui/icons-material/Description';
import { useAppContext } from '../../../../../../core/context/AppContext';
import PublicIcon from '@mui/icons-material/Public';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { useProfileContext } from '../../../../context/ProfileContext';
import { format } from 'date-fns';
import { dateFormat } from '../../../../../../core/helpers/dateFormat';
import CustomSelect from '../../../../components/shared/select/CustomSelect';
import { countries } from '../../../../../../core/staticData/countries';

interface ProfileDetailsProps {

}

const ProfileDetails: FC<ProfileDetailsProps> = () => {
    const theme = useTheme();
    const { isLoading } = useAppContext();
    const { profileData, onEditProfile, setProfileData } = useProfileContext();

    const changeGander = async(gender : string) : Promise<void> => {        
        if(!profileData) return;

        const updatedProfileData = {
            ...profileData,
            sex: gender,
        };

        onEditProfile(updatedProfileData);
    }

    const changeCountry = async(countryId : number) : Promise<void> => {
        if(!profileData) return;

        const country : {name : string, id : number} | undefined = countries.find(country => country.id === countryId);

        const updatedProfileData = {
            ...profileData,
            country: country?.name || '',
        };

        onEditProfile(updatedProfileData);
    }

    const activeCountry = () : number | string  => {
        const country : number | undefined = countries.find(country => country.name === profileData?.country)?.id;
        return country || '';
    }

    return (
        <>
            {profileData &&
                <Box sx={{
                    backgroundColor: 'white',
                    borderRadius: '6px',
                    boxShadow: 3,
                    width: '100%',
                    minHeight: '100%'
                }}>
                    <Box sx={{
                        padding: '20px 25px',
                        color: theme.palette.text.primary,
                        fontWeight: 400,
                        fontSize: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: `1px solid ${theme.palette.text.disabled}`,
                    }}>
                        <Box>Details</Box>
                    </Box>
                    <Box sx={{ padding: '20px 25px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{
                            width: '85%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 0 40px 0',
                        }}>
                            <FrameIcon
                                icon={CelebrationIcon}
                            />
                            <TextField
                                disabled={true}
                                id="standard-basic"
                                label="Date of birth"
                                variant="standard"
                                value={format(profileData.dateOfBirth, dateFormat)}
                                onBlur={() => { }}
                                sx={{
                                    width: '90%',
                                    marginLeft: '20px',
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: "#000000",
                                    },
                                    '& .MuiInput-underline:before': {
                                        borderBottom: 0,
                                    },
                                    '& .MuiInputLabel-root.Mui-disabled': {
                                        color: '#686868',
                                    },
                                }} />
                        </Box>
                        <Box sx={{
                            width: '85%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 0 40px 0',
                        }}>
                            <FrameIcon
                                icon={DescriptionIcon}
                            />
                            <TextField
                                rows={2}
                                multiline
                                disabled={isLoading}
                                id="standard-basic"
                                label="Description"
                                variant="standard"
                                value={profileData.description}
                                onBlur={() => onEditProfile(profileData) }
                                onChange={(event) => {
                                    setProfileData({
                                        ...profileData,
                                        description: event.target.value
                                    })
                                }}
                                sx={{
                                    width: '90%',
                                    marginLeft: '20px'
                                }} />
                        </Box>
                        <Box sx={{
                            width: '85%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 0 40px 0',
                        }}>
                            <FrameIcon
                                icon={PublicIcon}
                            />
                           <CustomSelect
                            options={countries}
                            value={activeCountry()}
                            onChange={(id) => changeCountry(id)}
                            label='Country'
                            standard={true}
                            sx={{ width: '95%', margin: '0 0 0 16px'}}
                        />
                        </Box>
                        <Box sx={{
                            width: '85%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 0 40px 0',
                        }}>
                            <FrameIcon
                                icon={LocationCityIcon}
                            />
                            <TextField
                                disabled={isLoading}
                                id="standard-basic"
                                label="City"
                                variant="standard"
                                value={profileData.city}
                                onBlur={() => { onEditProfile(profileData)}}
                                onChange={(event) => {
                                    setProfileData({
                                        ...profileData,
                                        city: event.target.value
                                    })
                                }}
                                sx={{
                                    width: '90%',
                                    marginLeft: '20px'
                                }} />
                        </Box>
                        <Box sx={{
                            width: '85%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 0 20px 0',
                        }}>
                            <Box sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button 
                                    variant={profileData.sex === 'Male' ? 'contained' : 'outlined'} 
                                    sx={{ margin: '0 10px 0 0' }}
                                    onClick={() => changeGander('Male')}>
                                        Male
                                    </Button>
                                    <Button 
                                    variant={profileData.sex === 'Female' ? 'contained' : 'outlined'}
                                    onClick={() => changeGander('Female')}>
                                        Female
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
        </>
    );
}

export default ProfileDetails;
