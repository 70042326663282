import { Box, SvgIconTypeMap, Typography, useTheme } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import type { FC } from 'react';
import React from 'react';

interface TravelStatsItemProps {
    label: string,
    value: string | number,
    unit: string,
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

const TravelStatsItem: FC<TravelStatsItemProps> = (props) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                height: '100%',
                boxShadow: 5,
                borderRadius: '6px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'radial-gradient(circle, rgba(45,221,183,1) 38%, rgba(37,186,154,1) 100%)',
                position: 'relative',
                overflow: 'hidden'
            }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                overflow: 'hidden',
                right: '-40px',
                bottom: '-40px',
            }}>
                {React.createElement(props.icon, { sx: { fontSize: '160px', color: theme.palette.primary.main } })}
            </Box>
            <Box sx={{zIndex: 1, position: 'absolute', left: '30px', top: '30px'}}>
                <Typography variant='body1' sx={{ color: 'white' }}>{props.label}</Typography>
                <Typography variant='h4' sx={{ color: 'white', margin: '2px 0 0 0', fontWeight: '600', fontSize: '24px' }}>{props.value} {props.unit}</Typography>
            </Box>
        </Box>
    );
}

export default TravelStatsItem;
