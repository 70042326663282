import { AxiosResponse } from "axios";
import api from "../api/config";
import { ProfilePutType } from "../pages/user/types/api/profile/ProfilePutType";
import { ProfileGetType } from "../pages/user/types/api/profile/ProfileGetType";
import { ProfilePicUpdateType } from "../pages/user/types/api/profile/ProfilePicUpdateType";

const endpoint = 'profile'

export const getProfile = async() : Promise<AxiosResponse<ProfileGetType>> => {
    return await api.get(endpoint);
}

export const editProfile = async(data : ProfileGetType) : Promise<AxiosResponse<ProfileGetType>> => {

    const editedData : ProfilePutType = {
        firstName : data.firstName,
        lastName : data.lastName,
        sex : data.sex,
        dateOfBirth : data.dateOfBirth,
        city : data.city,
        country : data.country,
        visibility : data.visibility,
        description : data.description
    }
    return await api.put(endpoint, editedData);
}

export const setProfilePicture = async(image : File) : Promise<AxiosResponse<ProfilePicUpdateType>> => {
    const data = new FormData();
    data.append('image', image);
    return await api.post(`${endpoint}/picture?type=profile`, data);
}
