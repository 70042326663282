import { Avatar, Box, Button, TextField, Typography, useTheme } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { FC, useRef, useState } from 'react';
import { useAppContext } from '../../../../../../core/context/AppContext';
import { useProfileContext } from '../../../../context/ProfileContext';
import BackupIcon from '@mui/icons-material/Backup';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { setProfilePicture } from '../../../../../../services/profileService';

interface AuthDataProps {

}

const PersonalData: FC<AuthDataProps> = () => {
    const theme = useTheme();
    const { isLoading } = useAppContext();
    const { profileData, onEditProfile, setProfileData } = useProfileContext();
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [photoUploaded, setPhoto] = useState<string | undefined>(undefined);
    const [selectedFile, setSelectedFile] = useState<File | null>(null); 

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => setPhoto(reader.result as string);
            reader.readAsDataURL(file);
            setSelectedFile(file); 
        }
    };

    const resetFile = () => {
        setPhoto(undefined);
        setSelectedFile(null);
        if (fileInputRef.current) fileInputRef.current.value = ''; 
    }

    const changeVisiblity = async (visibility: string): Promise<void> => {
        if (!profileData) return;

        const updatedProfileData = {
            ...profileData,
            visibility: visibility,
        };

        onEditProfile(updatedProfileData);
    }

    const handleCameraClick = () => {
        if (fileInputRef.current) fileInputRef.current.click();
    };

    const onFileUpload = async() => {
        if(!selectedFile) return;
        const res = await setProfilePicture(selectedFile)
        if(profileData)
            setProfileData({
                ...profileData,
                profilePic : res.data.url
            })
        resetFile(); 
    }

    return (
        <>
            {profileData &&
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px',
                    backgroundColor: 'white',
                    borderRadius: '6px',
                    boxShadow: 3
                }}>
                    <Box sx={{ position: 'relative' }}>
                        <Avatar
                            src={photoUploaded ? photoUploaded : profileData?.profilePic}
                            sx={{
                                height: '200px',
                                width: '200px',
                            }}
                        />
                        <Box sx={{
                            position: 'absolute',
                            bgcolor: 'white',
                            width: '50px',
                            height: '50px',
                            top: '0',
                            right: '0',
                            borderRadius: '100px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}>
                            <CameraAltIcon onClick={handleCameraClick} sx={{ color: theme.palette.text.secondary, fontSize: '28px' }} />
                            <input
                                type="file"
                                accept="image/*"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </Box>
                        {photoUploaded &&
                            <>
                                <Box sx={{
                                    position: 'absolute',
                                    boxShadow: 3,
                                    bgcolor: 'white',
                                    width: '40px',
                                    height: '40px',
                                    top: '5px',
                                    right: '-50px',
                                    borderRadius: '100px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer'
                                }}>
                                    <DoneIcon onClick={() => onFileUpload()} sx={{ color: 'green', fontSize: '24px' }} />
                                </Box>
                                <Box sx={{
                                    position: 'absolute',
                                    boxShadow: 3,
                                    bgcolor: 'white',
                                    width: '40px',
                                    height: '40px',
                                    top: '5px',
                                    right: '-100px',
                                    borderRadius: '100px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer'
                                }}>
                                    <CloseIcon onClick={() => resetFile()} sx={{ color: 'red', fontSize: '24px' }} />
                                </Box>
                            </>
                        }
                    </Box>
                    <Box sx={{
                        width: '80%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px 0'
                    }}>
                        <TextField
                            fullWidth
                            disabled={isLoading}
                            id="standard-basic"
                            label="Name"
                            variant="standard"
                            value={profileData?.firstName}
                            onBlur={() => onEditProfile(profileData)}
                            onChange={(event) => {
                                setProfileData({
                                    ...profileData,
                                    firstName: event.target.value
                                });
                            }}
                        />
                    </Box>
                    <Box sx={{
                        width: '80%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px 0'
                    }}>
                        <TextField
                            fullWidth
                            disabled={isLoading}
                            id="standard-basic"
                            label="Lastname"
                            variant="standard"
                            value={profileData?.lastName}
                            onBlur={() => onEditProfile(profileData)}
                            onChange={(event) => {
                                setProfileData({
                                    ...profileData,
                                    lastName: event.target.value
                                });
                            }} />
                    </Box>
                    <Box sx={{
                        width: '80%',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '10px 0'
                    }}>
                        <Typography sx={{ fontSize: '13px', color: theme.palette.text.secondary, margin: '0 0 10px 0' }}>Account visibility</Typography>
                        <Box>
                            <Button
                                variant={profileData.visibility === 'public' ? 'contained' : 'outlined'}
                                sx={{ margin: '0 10px 0 0' }}
                                onClick={() => changeVisiblity('public')}>
                                Public
                            </Button>
                            <Button
                                variant={profileData.visibility === 'private' ? 'contained' : 'outlined'}
                                onClick={() => changeVisiblity('private')}>
                                Private
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{
                        width: '100%',
                        padding: '10px 0',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <Button color='error'>Delete account</Button>
                    </Box>
                </Box>
            }
        </>
    );
}

export default PersonalData;
