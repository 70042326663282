import React, { createContext, useContext, useState, useEffect } from 'react';

interface DropdownContextType {
    openDropdown: string | null;
    setOpenDropdown: (id: string | null) => void;
}

const DropdownContext = createContext<DropdownContextType | undefined>(undefined);

export const DropdownProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [openDropdown, setOpenDropdown] = useState<string | null>(null);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            const dropdownElement = document.querySelector('.dropdown'); 
            if (dropdownElement && !dropdownElement.contains(event.target as Node)) setOpenDropdown(null); 
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [openDropdown]);

    return (
        <DropdownContext.Provider value={{ openDropdown, setOpenDropdown }}>
            {children}
        </DropdownContext.Provider>
    );
};

export const useDropdownContext = () => {
    const context = useContext(DropdownContext);
    if (!context) {
        throw new Error('useDropdownContext must be used within a DropdownProvider');
    }
    return context;
};
