import { useEffect, useState, type FC } from 'react';
import { LoadingButton } from '@mui/lab';
import { Card, Box, Typography, FormControlLabel, Checkbox, Alert } from '@mui/material';
import DynamicForm from '../../../../shared/ui/form/DynamicForm';
import { loginFormConfig } from '../../forms/loginFormConfig';
import background from '../../../../assets/images/background.jpg';
import theme from '../../../../core/themes/theme';
import { LoginType } from '../../types/loginType';
import { login } from '../../../../services/authService';
import { useAppContext } from '../../../../core/context/AppContext';
import { useNavigate } from 'react-router-dom';
import { useAccountConfirmationContext } from '../../../../core/context/AccountConfirmationContext';

interface LoginPageProps { }

const LoginPage: FC<LoginPageProps> = () => {
    const navigate = useNavigate();
    const { isLoading, error, setError} = useAppContext();
    const { setAccountConfirmation } = useAccountConfirmationContext();

    const [formValues, setValues] = useState<LoginType>({
        email: '',
        password: '',
    })

    const loginHandle = async () => {
        await login(formValues)
            .then(res => {
                localStorage.setItem('token', res.data.access_token)
                localStorage.setItem('userId', res.data.id.toString())
                localStorage.setItem('isEnabled', Number(res.data.isEnabled).toString());
                setAccountConfirmation(Number(res.data.isEnabled));
                navigate('/app/dashboard');
            })
            .catch((e : Error) => {})
    };

    const onFormValuesChange = (data: Partial<LoginType>) => {
        setValues((prevValue) => ({
            ...prevValue,
            ...data,
        }));
        setError(null);
    };

    useEffect(() => {
        document.title = "FollowMe | Sign in"; 
    }, [])

    return (
        <Box>
            <Card
                sx={{ width: 360, borderRadius: '10px', boxShadow: '0px 0px 24px -7px rgba(66, 68, 90, 1);' }}
            >
                <Box
                    sx={{
                        height: 200,
                        width: '100%',
                        backgroundImage: `url(${background})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            backgroundColor: '#00000049',
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            padding: '0 28px'
                        }}
                    >
                        <Typography
                            variant="h3"
                            color="white">
                            Sign In
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ padding: '40px 28px' }}>
                    <DynamicForm
                        formConfig={loginFormConfig}
                        onChange={onFormValuesChange}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            padding: '10px 0'
                        }}>
                        <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label={
                                <Typography
                                    variant='body2'
                                    color={theme.palette.text.secondary}
                                    sx={{ fontWeight: '400', fontSize: '12px' }}>
                                    Remember Me
                                </Typography>
                            }
                        />
                        <Typography variant='body2'
                            onClick={() => navigate('/auth/password-reset')} 
                            color={theme.palette.primary.main} 
                            sx={{ marginLeft: '4px', cursor: 'pointer', fontWeight: '400', fontSize: '12px' }}>
                            Forgot your password?
                        </Typography>
                    </Box>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        size='large'
                        loading={isLoading}
                        onClick={loginHandle}
                        fullWidth>
                        Login
                    </LoadingButton>
                    <Typography
                        variant='body2'
                        color={theme.palette.text.secondary}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '20px',
                            fontWeight: '400',
                            fontSize: '12px'
                        }}>
                        Don't have an account?
                        <Typography variant='body2'
                            onClick={() => navigate('/auth/register')} 
                            color={theme.palette.primary.main} 
                            sx={{ marginLeft: '4px', cursor: 'pointer', fontWeight: '400',
                                fontSize: '12px' }}>
                            Sign up now
                        </Typography>
                    </Typography>
                </Box>
            </Card>
            {error ? 
                <Alert 
                severity="error"
                sx={{position: 'fixed', right: '20px', bottom: '20px', padding: '10px 20px'}}>
                    {error.message}
                </Alert> : null
            }
        </Box>
    );
}

export default LoginPage;
