import React, { createContext, useContext, useState } from 'react';

interface EmailSelectionContextType {
    selectedEmails: string[];
    toggleEmail: (email: string) => void;
    resetSelectedEmails: () => void; 
}

const EmailSelectionContext = createContext<EmailSelectionContextType | undefined>(undefined);

export const EmailSelectionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

    const toggleEmail = (email: string) => {
        setSelectedEmails((prev) =>
            prev.includes(email) ? prev.filter((item) => item !== email) : [...prev, email]
        );
    };

    const resetSelectedEmails = () => {
        setSelectedEmails([]); 
    };

    return (
        <EmailSelectionContext.Provider value={{ selectedEmails, toggleEmail, resetSelectedEmails }}>
            {children}
        </EmailSelectionContext.Provider>
    );
};

export const useEmailSelectionContext = () => {
    const context = useContext(EmailSelectionContext);
    if (!context) {
        throw new Error('useEmailSelectionContext must be used within an EmailSelectionProvider');
    }
    return context;
};