import React from 'react';
import { Avatar, Box, Checkbox, ListItem, ListItemAvatar, ListItemButton, Typography, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getUsers } from '../../../../../services/userService';
import { UserType } from '../../../types/api/shared/UserType';
import ListDropdown from '../../../components/listDropdown/ListDropdown';
import { inviteUser } from '../../../../../services/groupService';
import { useEmailSelectionContext } from '../context/EmailSelectionContext';

interface AddMemberCompoentProps {
    onMemberAdd : () => void;
}

const AddMemberCompoent: React.FC<AddMemberCompoentProps> = (props) => {
    const { groupId } = useParams();
    const theme = useTheme();
    const { selectedEmails, toggleEmail, resetSelectedEmails } = useEmailSelectionContext();

    const fetchUsers = async (search: string) => {
        const res = await getUsers(search);
        return res.data.paginatedData || [];
    };

    const submitUsers = async () => {
        for (const email of selectedEmails) await inviteUser({email : email}, Number(groupId));
        resetSelectedEmails();
        props.onMemberAdd();
    };

    const renderUsersItem = (user: UserType) => {
        const isSelected = selectedEmails.includes(user.email);
        return (
            <ListItem key={user.email} secondaryAction={
                <Checkbox
                    edge="end"
                    onChange={() => toggleEmail(user.email)}
                    checked={isSelected}
                />
            }>
                <ListItemButton>
                    <ListItemAvatar sx={{ display: 'flex' }}>
                        <Avatar src={user.profilePic}></Avatar>
                        <Box sx={{ margin: '0 0 0 20px' }}>
                            <Typography variant="body2">{user.username}</Typography>
                            <Typography variant="body2" sx={{ fontWeight: 300, color: theme.palette.text.secondary, fontSize: '12px' }}>
                                {user.email}
                            </Typography>
                        </Box>
                    </ListItemAvatar>
                </ListItemButton>
            </ListItem>
        );
    };

    return (
        <ListDropdown
            titlePlaceholder="User email"
            fetchItems={fetchUsers}
            onSubmit={submitUsers}
            renderItem={renderUsersItem}
            noDataMessage="No users"
        />
    );
};

export default AddMemberCompoent;