import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginPage from './pages/login/LoginPage';
import RegisterPage from './pages/register/RegisterPage';
import PasswordResetPage from './pages/passwordReset/PasswordResetPage';
import NewPasswordPage from './pages/newPassword/NewPasswordPage';
import NotFoundPage from '../errors/NotFound';
import AccountConfirmationPage from './pages/account-confirm/AccountConfirmationPage';

interface AuthRoutesProps {}

const AuthRoutes: FC<AuthRoutesProps> = () => {
    return (
        <Routes>
            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="register/:token" element={<AccountConfirmationPage />} />
            <Route path="password-reset" element={<PasswordResetPage />} />
            <Route path="new-password/:token" element={<NewPasswordPage />} />
            <Route path='*' element={<NotFoundPage/>} ></Route>
        </Routes>
    );
}

export default AuthRoutes;
