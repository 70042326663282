import { TravelSortingType } from "../types/ui/travelSortingType";

export const setSorting = (currentSorting : string | undefined, sortingField : string, sortOrder : string | undefined) : TravelSortingType => {
    if(currentSorting === undefined) currentSorting = sortingField;
    if (currentSorting !== sortingField) return { sortField: sortingField, sortOrder: 'asc' };
   
    const newSortingOptions : TravelSortingType = {sortField : sortingField, sortOrder : sortOrder}
    switch(sortOrder){
        case 'asc':
            newSortingOptions.sortOrder = 'desc';
            break;
        case 'desc':
            newSortingOptions.sortOrder = undefined;
            break;
        default:
            newSortingOptions.sortOrder = 'asc';
            break;
    }

    return newSortingOptions;
}