export const passwordResetFormConfig = {
    email: {
        label: 'Email',
        fieldType: 'text',
        value: '',
        error: undefined,
        validation: {
            required: true,
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        }
    },
}