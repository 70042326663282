import { Box, useTheme } from '@mui/material';
import type { FC } from 'react';
import { ActionItemType } from '../../types/ui/actionItemType';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ActionDropdown from './ActionDropdown';
import { useDropdownContext } from '../../../../core/context/DropdownContext';
import React from 'react';

interface ActionProps {
    actionName : string,
    actionItems : ActionItemType[],
    sx?: object; 
}

const Action: FC<ActionProps> = ({actionItems, sx, actionName}) => {
    const theme = useTheme();
    const { openDropdown, setOpenDropdown } = useDropdownContext();
  
    const handleActionClick = async (action: ActionItemType) => {
        setOpenDropdown(null);
        await action.onAction(null);
    };

    return (
        <Box sx={{ position: 'absolute', right: '20px', top: '14px', cursor: 'pointer'}}>
        <MoreHorizIcon 
            sx={{ 
                transition: 'all ease-in-out 0.1s',
                fontSize: '28px', 
                color: openDropdown === actionName ? theme.palette.text.disabled : theme.palette.text.primary}} 
                 onClick={() => setOpenDropdown(actionName)} />
        {openDropdown === actionName && 
            <ActionDropdown sx={{ zIndex: 1, top: '40px', ...sx}}>
                <Box>
                    {actionItems.map(item => (
                        <Box
                        onClick={() => handleActionClick(item)}
                        sx={{
                            display: 'flex',
                            minWidth: '200px',
                            alignItems: 'center',
                            width: '100%',
                            padding: '10px 0',
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: theme.palette.background.default
                            },
                        }}>
                        {React.createElement(item.icon, { sx: { margin : '0 10px 0 10px'} })}
                        {item.label}
                    </Box>
                    ))}
                </Box>
            </ActionDropdown>
        }
    </Box>
    );
}

export default Action;
