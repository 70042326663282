import { Button, Paper, Table, TableBody, TableContainer } from '@mui/material';
import { useEffect, useState, type FC } from 'react';
import NoDataComponent from '../../../components/shared/travelTable/components/NoDataComponent';
import { GroupMemberType } from '../../../types/api/groups/GroupMemberType';
import { getMembers, removeMember } from '../../../../../services/groupService';
import { useDropdownContext } from '../../../../../core/context/DropdownContext';
import ActionDropdown from '../../../components/actionDropdown/ActionDropdown';
import { canInviteUsers } from '../permisions';
import AddMemberCompoent from './AddMemberComponent';
import TableTitle from '../../../components/tables/tableTitle/TableTitle';
import GroupMembersRow from './GroupMembersRow';
import Loader from '../../../../../shared/ui/loader/Loader';
import { useAppContext } from '../../../../../core/context/AppContext';

interface GroupMembersProps {
    isOwner: boolean | undefined,
    groupId: number,
    role: string,
}

const GroupMembers: FC<GroupMembersProps> = (props) => {
    const { isLoading } = useAppContext();
    const { openDropdown, setOpenDropdown } = useDropdownContext();
    const [members, setMembers] = useState<GroupMemberType[]>([]);

    const fetchData = async () => {
        await getMembers(props.groupId)
            .then(res => {
                setMembers(res.data);
            })
    }

    const onRemoveMember = async (userId: number) => {
        await removeMember(userId, props.groupId)
            .then(res => {
                const filteredMembers: GroupMemberType[] = members.filter(member => member.id !== userId);
                setMembers(filteredMembers);
            })
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <TableContainer component={Paper} sx={{ boxShadow: 5, borderRadius: '6px', height: '100%', overflow: 'visible', position: 'relative' }}>
            <TableTitle title="Group's members">
                {canInviteUsers(props.isOwner || false, props.role) &&
                    <Button variant='outlined' size='small' onClick={() => setOpenDropdown('addMemberToGroup')}>+ Add member</Button>
                }
            </TableTitle>
            <Table aria-label="simple table">

                <TableBody sx={{ position: 'relative' }}>
                    {isLoading ? <Loader/> : null}
                    {members.map((member) => (
                        <GroupMembersRow
                            member={member}
                            isOwner={props.isOwner || false}
                            role={props.role}
                            onRemoveMember={(memberId) => onRemoveMember(memberId)}
                        />
                    ))}
                </TableBody>
            </Table>
            {
                members.length < 1 ? <NoDataComponent
                    size={1}
                    title='No members'
                    details="The group has no members"
                /> : null
            }
            {openDropdown === 'addMemberToGroup' &&
                <ActionDropdown sx={{ maxWidth: '600px', width: '100%', right: '25px' }}>
                    <AddMemberCompoent
                        onMemberAdd={() => { fetchData(); setOpenDropdown(null); }}
                    />
                </ActionDropdown>
            }
        </TableContainer>
    );
}

export default GroupMembers;
