import { Box, Typography, useTheme } from '@mui/material';
import type { FC } from 'react';

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
    const theme = useTheme();
    
    return (
        <Box sx={{
         width: '100%',
         height: '80px',
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         borderTop: `1px solid ${theme.palette.text.disabled}`,
         margin: '40px 0 0 0 ',
         backgroundColor: 'white',
        }}>
            <Typography variant='body2' sx={{color: theme.palette.text.secondary, fontWeight: '400'}}>Copyright FollowMe © 2024</Typography>
        </Box>
    );
}

export default Footer;
