import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthRoutes from './pages/auth/AuthRoutes';
import UserLayout from './pages/user/UserLayout';
import UserRoutes from './pages/user/UserRoutes';
import ProtectedRoute from './pages/auth/guard/ProtectedRoute';
import NotFoundPage from './pages/errors/NotFound';
import HomePage from './pages/home/HomePage';

const AppRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path='' element={<HomePage/>}/>
            <Route path="/auth/*" element={<AuthRoutes />} />
            <Route element={<ProtectedRoute/>}>
                <Route path="/app/*" element={<UserLayout />}>
                    <Route path="*" element={<UserRoutes />} /> {/* Zagnieżdżone trasy */}
                </Route>
            </Route>
            <Route path='*' element={<NotFoundPage/>} ></Route>
        </Routes>
    );
};

export default AppRoutes;