import { NavItemsType } from "../../../types/ui/navItemsType";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PlaceIcon from '@mui/icons-material/Place';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import InfoIcon from '@mui/icons-material/Info';
import ShareIcon from '@mui/icons-material/Share';

export const navItems : NavItemsType[] = [
    {
        title : 'Travel',
        items : [
            {
                label : 'Dashboard',
                path : 'dashboard',
                icon : DashboardIcon,
            },
            {
                label : 'Travels',
                path : 'travels',
                icon : PlaceIcon
            },
            {
                label : 'Groups',
                path : 'groups',
                icon : Diversity3Icon
            },
        ]
    },
    {
        title : 'Socials',
        items : [
            {
                label : 'My profile',
                path : 'my-profile',
                icon : AccountBoxIcon,
            },
            {
                label : 'Users',
                path : 'users',
                icon : GroupAddIcon
            },
            {
                label : 'Invite friends',
                path : 'invites',
                icon : ShareIcon
            },
        ]
    },
    {
        title : 'Others',
        items : [
            {
                label : 'About',
                path : 'about',
                icon : InfoIcon,
            },
        ]
    }
]
   
