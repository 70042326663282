import { Box, Button, TextField, useTheme } from '@mui/material';
import type { FC } from 'react';
import FrameIcon from '../../../../../../shared/ui/frameIcon/FrameIcon';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import { useProfileContext } from '../../../../context/ProfileContext';

interface AuthDataProps {

}

const AuthData: FC<AuthDataProps> = () => {
    const theme = useTheme();
    const { profileData } = useProfileContext();

    return (
        <>
            {profileData &&
                <Box sx={{
                    backgroundColor: 'white',
                    borderRadius: '6px',
                    boxShadow: 3,
                    width: '100%'
                }}>
                    <Box sx={{
                        padding: '20px 25px',
                        color: theme.palette.text.primary,
                        fontWeight: 400,
                        fontSize: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: `1px solid ${theme.palette.text.disabled}`,
                    }}>
                        <Box>Authorization</Box>
                    </Box>
                    <Box sx={{ padding: '20px 25px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{
                            width: '85%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 0 20px 0',
                        }}>
                            <FrameIcon
                                icon={EmailIcon}
                            />
                            <TextField
                                disabled={true}
                                id="standard-basic"
                                label="Email"
                                variant="standard"
                                value={profileData.user.email}
                                sx={{
                                    width: '90%',
                                    marginLeft: '20px',
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: "#000000",
                                    },
                                    '& .MuiInput-underline:before': {
                                        borderBottom: 0,
                                    },
                                    '& .MuiInputLabel-root.Mui-disabled': {
                                        color: '#686868',
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{
                            width: '85%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 0 20px 0',
                        }}>
                            <FrameIcon
                                icon={PersonIcon}
                            />
                            <TextField
                                disabled={true}
                                id="standard-basic"
                                label="Username"
                                variant="standard"
                                value={profileData.user.username}
                                sx={{
                                    width: '90%',
                                    marginLeft: '20px',
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: "#000000",
                                    },
                                   '& .MuiInput-underline:before': {
                                        borderBottom: 0,
                                    },
                                    '& .MuiInputLabel-root.Mui-disabled': {
                                        color: '#686868',
                                    },
                                }} />
                        </Box>
                        <Box sx={{
                            width: '85%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 0 20px 0',
                        }}>
                            <Box sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <Button variant='contained'>New Password</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
        </>
    );
}

export default AuthData;
