import { TableRow, TableCell, Box, Avatar, Typography, useTheme } from '@mui/material';
import { useState, type FC } from 'react';
import ActionDropdown from '../../../components/actionDropdown/ActionDropdown';
import { canRemoveUsers, canSetPermissions, permissions } from '../permisions';
import { GroupMemberType } from '../../../types/api/groups/GroupMemberType';
import { useDropdownContext } from '../../../../../core/context/DropdownContext';
import { useNavigate, useParams } from 'react-router-dom';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import CheckIcon from '@mui/icons-material/Check';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { PermissionType } from '../../../../auth/types/permissionType';
import { setPermission } from '../../../../../services/groupService';

interface GroupMembersRowProps { 
    member : GroupMemberType
    isOwner : boolean,
    role : string
    onRemoveMember : (memberId : number) => void;
}

const GroupMembersRow: FC<GroupMembersRowProps> = ({member, isOwner, role, onRemoveMember}) => {
    const { groupId } = useParams();
    const { openDropdown, setOpenDropdown } = useDropdownContext();
    const navigate = useNavigate();
    const theme = useTheme();

    const [permissionListActive, setPermissionState] = useState<boolean>(false);

    const loggedUserId : number = Number(localStorage.getItem('userId'));
    const permisions = permissions;

    const viewProfile = (userId: number) => {
        navigate(`/app/users/${userId}`);
        setOpenDropdown(null);
    }

    const setRoleColor = (role: string) => {
        if (role === 'ADMIN')
            return '#ffab3d'

        if (role === 'MEMBER')
            return '#00b7c6'

        return theme.palette.primary.main;
    }

    const onSetPermission = async (role: string, memberId: number) => {
        const permission: PermissionType = { groupId: Number(groupId), userId: memberId, role: role }
        await setPermission(permission)
            .then(res => {
                member.role = role.toUpperCase();
            })

        setOpenDropdown(null);
    }

    return (
        <TableRow
            key={member.username}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { cursor: 'pointer' } }}
        >
            <TableCell align='left' component="th" scope="row">
                <Box sx={{ display: 'flex' }}>
                    <Avatar src={member.profilePic}></Avatar>
                    <Box sx={{ margin: '0 0 0 20px' }}>
                        <Typography variant='body2'>{member.username}</Typography>
                        <Typography variant='body2' sx={{ fontWeight: 300, color: theme.palette.text.secondary, fontSize: '12px' }}>{member.email}</Typography>
                    </Box>
                </Box>
            </TableCell>
            <TableCell align='right' sx={{ position: 'relative' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box sx={{
                        backgroundColor: setRoleColor(member.role),
                        color: 'white',
                        padding: '5px 10px',
                        textAlign: 'center',
                        fontSize: '12px',
                        borderRadius: '30px',
                        margin: '0 20px 0 0',
                        fontWeight: 600
                    }}>
                        {member.role}
                    </Box>
                    <MoreHorizIcon onClick={() => { setOpenDropdown(`groupMember${member.id}`); setPermissionState(false) }} />
                    {openDropdown === `groupMember${member.id}` &&
                        <ActionDropdown sx={{ top: '60px', right: '10px', overflow: 'visible', zIndex: 2 }}>
                            <Box>
                                {canRemoveUsers(isOwner || false, role, loggedUserId, member.id) &&
                                    <Box
                                        onClick={() => onRemoveMember(member.id)}
                                        sx={{
                                            display: 'flex',
                                            minWidth: '200px',
                                            alignItems: 'center',
                                            width: '100%',
                                            padding: '10px 0',
                                            cursor: 'pointer',
                                            fontWeight: 500,
                                            '&:hover': {
                                                backgroundColor: theme.palette.background.default
                                            },
                                        }}>
                                        <PersonRemoveIcon sx={{ margin: '0 10px 0 10px', fontSize: '22px' }} />
                                        Remove person
                                    </Box>
                                }
                                <Box
                                    onClick={() => viewProfile(member.id)}
                                    sx={{
                                        display: 'flex',
                                        minWidth: '200px',
                                        alignItems: 'center',
                                        width: '100%',
                                        padding: '10px 0',
                                        cursor: 'pointer',
                                        fontWeight: 500,
                                        '&:hover': {
                                            backgroundColor: theme.palette.background.default
                                        },
                                    }}>
                                    <AccountCircleIcon sx={{ margin: '0 10px 0 10px', fontSize: '22px' }} />
                                    View profile
                                </Box>
                                {canSetPermissions(isOwner || false, role, loggedUserId, member.id) &&
                                    <Box
                                        onClick={() => setPermissionState(!permissionListActive)}
                                        sx={{
                                            display: 'flex',
                                            minWidth: '200px',
                                            alignItems: 'center',
                                            width: '100%',
                                            padding: '10px 0',
                                            cursor: 'pointer',
                                            position: 'relative',
                                            fontWeight: 500,
                                            overflow: 'visible',
                                            '&:hover': {
                                                backgroundColor: theme.palette.background.default
                                            },
                                        }}>
                                        <ArrowLeftIcon sx={{ margin: '0 10px 0 10px' }} />
                                        <Box>
                                            Permissions
                                            {permissionListActive &&
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                        position: 'absolute',
                                                        minWidth: '140px',
                                                        top: '0',
                                                        left: '-148px',
                                                        backgroundColor: 'white',
                                                        boxShadow: theme.shadows[3],
                                                        borderRadius: '6px',
                                                        padding: '10px 0',
                                                    }}
                                                >
                                                    {
                                                        permisions.map(permision => (
                                                            <Box
                                                                onClick={() => onSetPermission(permision, member.id)}
                                                                sx={{
                                                                    width: '100%',
                                                                    padding: '10px 0',
                                                                    textAlign: 'start',
                                                                    '&:hover': {
                                                                        backgroundColor: theme.palette.background.default
                                                                    }
                                                                }}>
                                                                <Box sx={{
                                                                    margin: '0 0 0 20px',
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    {permision}
                                                                    {permision.toUpperCase() === member.role ?
                                                                        <CheckIcon sx={{ margin: '0 0 0 10px', fontSize: '18px' }} /> : null}
                                                                </Box>
                                                            </Box>
                                                        ))
                                                    }
                                                </Box>
                                            }
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </ActionDropdown>
                    }
                </Box>
            </TableCell>
        </TableRow>
    );
}

export default GroupMembersRow;
