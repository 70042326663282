import { Box, Button } from '@mui/material';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface HomePageProps {}

const HomePage: FC<HomePageProps> = () => {
    const navigate = useNavigate();

    return (
        <Box>
            <Button variant='outlined' onClick={() => navigate('/app/dashboard')}>Follow Me</Button>
        </Box>
    );
}

export default HomePage;
