import { Grid, Typography } from '@mui/material';
import { useEffect, useRef, useState, type FC } from 'react';
import TravelsTable from '../../components/shared/travelTable/TravelsTable';
import { TravelsGetType } from '../../types/api/travels/travelsGetType';
import { getRecentTravels } from '../../../../services/travelsService';
import LastTravelsStats from './components/LastTravelsStats';
import NoDataComponent from '../../components/shared/travelTable/components/NoDataComponent';
import { useNavContext } from '../../context/NavContext';
import Map from '../../components/shared/map/Map';
import ContentWrapper from '../../components/contentWrapper/contentWrapper';

interface DashboardProps { }

const Dashboard: FC<DashboardProps> = () => {
    const [travels, setTravels] = useState<TravelsGetType[]>([]);
    const { windowSize } = useNavContext();
    const tableRef = useRef<HTMLDivElement | null>(null);
    const [tableHeight, setTableHeight] = useState<number>(0);

    const fetchRecentTravels = async () => {
        await getRecentTravels()
            .then(res => {
                setTravels(res.data);
            })
    }

    useEffect(() => {
        document.title = "FollowMe | Dashboard";
        fetchRecentTravels();
    }, [])

    useEffect(() => {
        if (tableRef.current) setTableHeight(tableRef.current.offsetHeight);
    }, [travels, windowSize]);

    return (
        <ContentWrapper
            pageTitle='Recent travels'
        >
            {travels.length < 1 ?
                <Grid xs={12} sx={{
                    height: 'calc(100vh - 180px)',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    left: 0,
                    zIndex: 0,
                }}>
                    <NoDataComponent
                        size={1}
                        title='No data'
                        details="You don not have any trips yet"
                    />
                </Grid> :
                <>
                    <Grid item xs={12} xl={8}>
                        <div ref={tableRef}>
                            <TravelsTable
                                travels={travels}
                                sorting={null}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} xl={4} sx={{ height: '100%' }}>
                        <LastTravelsStats
                            tableHeight={tableHeight}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Map
                            travels={travels}
                        />
                    </Grid>
                </>
            }
        </ContentWrapper>

    );
}

export default Dashboard;
